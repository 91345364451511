import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "./components/utilities/uicomponents/Navbar";
import Login from "./components/profile/Login";
import { Outlet } from "react-router-dom";
import { getAuth, getRedirectResult, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import { allSubjects, toastOptions } from "./components/utilities/utilities";
import { collection, doc, getDoc, getDocs, getFirestore, query, Query } from "firebase/firestore";
import { SubjectsProvider } from "./components/utilities/subjectsContext";
import { useLocation } from "react-router-dom";
import Onboarding from "./components/profile/Onboarding";
import useWindowDimensions from "./components/utilities/windowDimensions";
import trackPathForAnalytics from "./Tracking";
import useDarkMode from "./components/utilities/darkMode";
import indexedSubjects from "./components/utilities/indexedSubjects";

const MainApp = () => {
	const [authLoaded, setAuthLoaded] = useState<boolean>(false);
	const [coursesLoaded, setCoursesLoaded] = useState<boolean>(false);
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const [subjects, setSubjects] = useState<allSubjects>({});
	const [mySubjects, setMySubjects] = useState<string[]>([]);
	const { width } = useWindowDimensions();

	const db = getFirestore();
	const auth = getAuth();

	const [isOnboarded, setIsOnboarded] = useState<boolean>(false);

	// Google Analytics tracking
	const { pathname, search } = useLocation();
	const analytics = useCallback(() => {
		trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
	}, [pathname, search]);

	useEffect(() => {
		analytics();
	}, [analytics]);

	useEffect(() => {
		try {
			getRedirectResult(auth);
		} catch (err) {
			console.warn(err);
			toast.error(err as string, toastOptions);
		}

		onAuthStateChanged(auth, async (user) => {
			if (user) {
				const q: Query = query(collection(db, "papers/"));

				const subjectSnapshot = await getDocs(q);

				let output: allSubjects = {};

				subjectSnapshot.docs.forEach((val) => {
					output[val.id] = val.data() as { [key: number]: { MG: string; Paper: string } };
				});

				const onboarded = await getDoc(doc(db, "users", user?.uid));
				const data = onboarded.data();
				if (data !== undefined) {
					setIsOnboarded(data["onboarded"]);

					const dbCourses = data["courses"];
					setMySubjects(dbCourses);
				} else {
					setMySubjects([]);
				}
				setSubjects(output);
				setCoursesLoaded(true);
				setLoggedIn(true);
			} else setLoggedIn(false);
			setAuthLoaded(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (width < 768) {
		console.log("On mobile, website not currently supported");
		return (
			<div className="w-screen h-screen flex flex-col justify-center items-center px-10 font-medium">
				<div className="leading-2">
					<h1 className="leading-tight text-4xl font-semibold text-text">
						Mobile Website in development.<br></br>😬
					</h1>
					<hr className="mt-6 mb-12 border-4 border-contrast w-20"></hr>
					<p className="leading-loose">
						Something went wrong!<br></br>
						<br></br>Our application is currently only designed for Tablet and Desktop use. Please be patient with us while we're still in development.
					</p>
				</div>
			</div>
		);
	} else if (!authLoaded) {
		return (
			<div className="w-screen h-screen flex flex-col justify-around items-center dark:bg-darkbg">
				<SyncLoader color="#CCD5AE" />
			</div>
		);
	} else if (!loggedIn) {
		return (
			<div className="flex w-screen h-screen overflow-hidden font-poppins">
				<Login />
			</div>
		);
		// Auth and login dont need courses but everything else does
	} else if (!coursesLoaded) {
		return (
			<div className="w-screen h-screen flex flex-col justify-around items-center  dark:bg-darkbg">
				<SyncLoader color="#CCD5AE" />
			</div>
		);
	} else if (!isOnboarded) {
		return (
			<div className="relative w-screen h-screen flex overflow-hidden ">
				<SubjectsProvider value={{ setMySubjects: (val) => setMySubjects(val), mySubjects: [], subjects: subjects, indexedSubjects: indexedSubjects }}>
					<Onboarding />
				</SubjectsProvider>
			</div>
		);
	} else {
		return (
			<div className="relative w-screen h-screen flex overflow-hidden font-poppins  dark:bg-darkbg">
				<SubjectsProvider value={{ setMySubjects: (val) => setMySubjects(val), mySubjects: mySubjects, subjects: subjects, indexedSubjects: indexedSubjects }}>
					<Navbar />
					<Outlet />
				</SubjectsProvider>
			</div>
		);
	}
};

const App = () => {
	const main = useRef<HTMLDivElement>(null);
	useDarkMode(main);

	return (
		<div id="main" ref={main}>
			<ToastContainer position="top-center" autoClose={5000} newestOnTop={false} closeOnClick rtl={false} draggable pauseOnFocusLoss={false} pauseOnHover theme="colored" />
			{MainApp()}
		</div>
	);
};

export default App;
