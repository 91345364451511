// import { collection, getDocs, getFirestore, Query, query } from "firebase/firestore";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";

interface props {
	setComboboxOutput: (subject: string) => void;
	comboboxInput?: string;
	className?: string;
	options: string[];
	clearOnSelect?: boolean;
	placeholder: string;
}

const usePrevious = (value: any) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const ComboboxInput = (props: props) => {
	const [inputText, setInputText] = useState<string>("");
	const [internalSelected, setInternalSelected] = useState<string>("");
	const { options, clearOnSelect, setComboboxOutput, placeholder, comboboxInput } = props;
	const prevInput = usePrevious(comboboxInput);

	useEffect(() => {
		if (prevInput === comboboxInput) {
			setComboboxOutput(internalSelected);
		} else setInternalSelected(comboboxInput);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [internalSelected, comboboxInput]);

	const filteredSelector =
		inputText === ""
			? options
			: options.filter((value, index) => {
					if (value.toLowerCase().includes(inputText.toLowerCase())) {
						return true;
					}
					return false;
			  });

	const renderOptions = () => {
		if (!filteredSelector || filteredSelector.length === 0) {
			return [
				<div key="nothing" className="relative cursor-default select-none py-2 px-4 text-gray-700">
					Nothing found
				</div>,
			];
		} else {
			return filteredSelector.map((subject) => (
				<Combobox.Option
					key={subject}
					value={subject}
					className={({ active }) => `text-start dark:text-white dark:bg-darkflat w-full relative cursor-default select-none py-2 px-7 ${active ? "bg-primary dark:bg-darkdecoration dark:text-text" : "text-gray-900"}`}
				>
					{({ selected, active }) => (
						<div>
							<span className={`block truncate w-max ${selected ? "font-medium" : "font-normal"}`}>{subject}</span>
							{selected ? (
								<span className={`absolute inset-y-0 -left-2 flex items-center pl-3 ${active ? "text-black" : "text-contrast"}`}>
									<CheckIcon className="h-5 w-5" aria-hidden="true" />
								</span>
							) : null}
						</div>
					)}
				</Combobox.Option>
			));
		}
	};

	return (
		<Combobox value={internalSelected} onChange={setInternalSelected}>
			<div className={"relative w-full " + props.className}>
				<Combobox.Button
					className="relative w-full"
					onClick={() => {
						if (clearOnSelect) {
							setInputText("");
							setInternalSelected("");
						}
					}}
				>
					<Combobox.Input
						placeholder={placeholder}
						onChange={(event) => setInputText(event.target.value)}
						className="max-h-12 w-full rounded-md px-6 py-3 bg-flatlight dark:bg-darkgray dark:text-white dark:placeholder:text-gray-400 placeholder:text-gray-500 border-none focus:ring-0 focus:bg-flat"
					/>

					<Combobox.Options className="max-h-52 max-w-lg overflow-x-hidden overflow-y-auto absolute bg-white dark:bg-darkbg rounded-md shadow-md z-10">{renderOptions()}</Combobox.Options>
				</Combobox.Button>
			</div>
		</Combobox>
	);
};

export default ComboboxInput;
