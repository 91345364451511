/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowTopRightOnSquareIcon, CheckIcon, DocumentChartBarIcon, DocumentTextIcon, LinkIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { worksheets } from "../../AlgoliaInit";
import { Timestamp, doc, collection, getDoc, getFirestore, where, query, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { worksheetObj } from "../utilities/utilities";
import Robot from "../utilities/RobotSVG";

const Course = () => {
    type WorksheetData = {
		name: string;
		course: string;
		shortDate: string;
		publicUrl: string;
	};

    type Subtopic = {
        text: string;
        indentLevel: number;
    };

    type CourseData = {
		courseName: string;
		courseCode: string;
        chips: string[];
		description: string;
		prerequisites: string;
        corequisites: string;
        eligibility: string;
        selfTuition: string;
        exclusions: string[];
        learnings: {
            title: string;
            subtopics: (string | Subtopic)[];
        }[];
        nesaPageLink: string;
        syllabusLink: string;
        courseSpecs?: string;
	};

    const db = getFirestore();
	const auth = getAuth();
    const navigate = useNavigate();

    const selectedCourse = useParams().course;
    const [courseFound, setCourseFound] = useState<null | boolean>(null);
    
    const [courseData, setCourseData] = useState<CourseData>({
        courseName: "",
        courseCode: "",
        chips: [],
        description: "",
        prerequisites: "",
        corequisites: "",
        eligibility: "",
        selfTuition: "",
        exclusions: [],
        learnings: [
            {
                title: "",
                subtopics: [],
            },
        ],
        nesaPageLink: "",
        syllabusLink: ""
    });

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const docRef = doc(db, 'courseDescriptions', 'courses');
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const coursesData = docSnapshot.data() as { [key: string]: CourseData };
                    const courseData = Object.values(coursesData).find(
                        (course) => course.courseName === selectedCourse
                    );
                    if (courseData) {
                        const newChips = await getChips(courseData);
                        courseData.chips = [...newChips, ...courseData.chips];
                        setCourseData(courseData);
                        setDocumentTitle(courseData.courseCode);
                        setCourseFound(true);
                    } else {
                        console.error('Course not found');
                        setCourseFound(false);
                        setDocumentTitle("Course");
                    }
                } else {
                    console.error('Document does not exist');
                    setCourseFound(false);
                    setDocumentTitle("Course");
                }
            } catch (error) {
                console.error('Error fetching course data:', error);
                setCourseFound(false);
                setDocumentTitle("Course");
            }
        };
    
        fetchCourseData();
        
    }, [db, selectedCourse]);

    const [worksheetTableData, setWorksheetTableData] = useState<React.ReactElement>(<div></div>);

    const noWorksheets: React.ReactElement = (
		<div className="w-full mt-4 py-4 px-4 rounded-md shadow-md h-full flex justify-start items-center gap-3 text-gray-500 bg-gray-100 dark:bg-darkgray dark:text-white">
			<MagnifyingGlassIcon className="w-8 h-8" />
			<div className="w-fit flex flex-col items-start text-sm lg:text-base font-normal">
				<span>Looks like you dont have any worksheets! </span>
				<button
					onClick={() => {
						navigate('/worksheets?new=true');
					}}
					className="text-contrast underline"
				>
					Make one now
				</button>
			</div>
		</div>
	);

    // const lockedWorksheets: React.ReactElement = (
	// 	<div className="w-full mt-4 py-4 px-4 rounded-md shadow-md h-full flex justify-start items-center gap-3 text-gray-500 bg-gray-100 dark:bg-darkgray dark:text-white">
	// 		<LockClosedIcon className="w-8 h-8" />
	// 		<div className="w-fit flex flex-col items-start text-sm lg:text-base font-normal">
	// 			<span>Worksheet generation not available. </span>
	// 			<span className="font-light">Contact your IT department or school administration office for access.</span>
	// 		</div>
	// 	</div>
	// );

    const setDocumentTitle = (courseCode: string) => {
        document.title = `HSC Buddy | ${courseCode}`;
    }

    const goToFinder = (course: string) => {
		const query = course.replace(" ", "%20");
		const url = `/question-finder?p=${query}`;

		navigate(url);
	};

    const goToPapers = (course: string) => {
		const query = course.replace(" ", "%20");
		const url = `/question-search?p=${query}`;

		navigate(url);
	};

    const getWorksheetTableData = (worksheetObj: WorksheetData[]) => {
		return worksheetObj.map((val, i) => {
			return (
				<div key={i} className={"grid grid-cols-6 2xl:grid-cols-7 gap-6 py-4 pr-6 pl-4 dark:text-white font-medium text-gray-800 " + (i % 2 === 0 ? "bg-gray-100 dark:bg-darktable1 " : "bg-gray-50 dark:bg-darktable2 ") + (i === 0 ? "rounded-t-xl " : "rounded-none ") + (i === worksheetObj.length - 1 ? "rounded-b-xl" : "rounded-none")}>
					<div className="flex gap-1 col-start-1 col-span-3 2xl:col-span-4">
						<DocumentTextIcon className="w-6" />
						<span className="justify-center items-center flex">{val["name"]}</span>
					</div>

					<span className="col-span-2 flex justify-center">{val["shortDate"]}</span>
					<span className="flex gap-2">
						<button
							onClick={() => {
								window.open(val["publicUrl"], "_blank");
							}}
						>
							<ArrowTopRightOnSquareIcon className="w-6" />
						</button>
						<button className="opacity-20 hover:cursor-not-allowed cursor-not-allowed">
							<LinkIcon className="w-6" />
						</button>
					</span>
				</div>
			);
		});
	};

    const timestampToMediumDate = (timestamp: Timestamp): string => {
        const date = timestamp.toDate();
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "long" });
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        const hours12 = hours % 12 || 12;
    
        const formattedTime = `${hours12}:${String(minutes).padStart(2, "0")}${ampm}`;
    
        return `${day} ${month} ${formattedTime}`;
    };
    

    const fetchDataFromFirestore = async (worksheetIds: string[]): Promise<WorksheetData[]> => {
        const user = auth.currentUser;
        if (user === null) return [];
      
        const unresolvedReturnObj = worksheetIds.map(async (id, i): Promise<WorksheetData | undefined> => {
          const query = doc(db, "worksheets/users/" + user.uid + "/" + id);
          const snap = await getDoc(query);
      
          if (snap && snap.data()) {
            const data = snap.data() as worksheetObj;
            //console.log(snap.data());
            return {
              publicUrl: data.publicUrl ?? "",
              name: data.name,
              course: data.course,
              shortDate: timestampToMediumDate(data.timestamp ?? new Timestamp(0, 0)),
            };
          } else {
            console.log(`No data available for worksheet with ID ${id}`);
            return undefined;
          }
        });
      
        const returnObj = await Promise.all(unresolvedReturnObj);
      
        return returnObj.filter(item => item !== undefined) as WorksheetData[];
      };
      


    const getLatestWorksheets = async () => {
		const user = auth.currentUser;
		if (user === null) return;

		try {
			const res = await worksheets.search("", {
				attributesToRetrieve: ["objectID"],
				filters: `userID:"${user.uid}" AND _tags:worksheet`, // Filter out results where the course field is empty
			});
			if (res.hits.length > 0) {
				try {
					const firestoreData = await fetchDataFromFirestore(res.hits.map((val) => val.objectID));

                    const nameToSearch = courseData.courseName;
                    if (nameToSearch) {
                        const filteredData = firestoreData.filter(item => item.course === nameToSearch);
                        if (filteredData.length <= 0) {
                            setWorksheetTableData(noWorksheets);
                        }
                        else {
                            setWorksheetTableData(<div className="dark:bg-darkbg flex flex-col font-semibold mt-4">{getWorksheetTableData(filteredData)}</div>);
                        }
                    } else {
                        setWorksheetTableData(noWorksheets);
                    }
				}
				catch (err) {
					setWorksheetTableData(noWorksheets);
                    console.log(err);
				}
			}
			else {
				setWorksheetTableData(noWorksheets);
			}

		} catch (error) {
			console.error("Error fetching worksheets:", error);
		}
	};

    useEffect(() => {
        if (courseData.courseName !== "" && courseData.courseName !== undefined && courseData !== undefined) {
            getLatestWorksheets();
        }	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseData]);

    const pushSchoolOfferChip = (offered: boolean, schoolCode: string): string => {
        return(`${schoolCode} offered ${offered ? '*yes*' : '*no*'}`);
    };

    const pushMyCourseChip = (myCourse: boolean): string => {
        return(`My course ${myCourse ? '*yes*' : '*no*'}`);
    }

    const getChips = async (courseData: CourseData) => {
        const user = auth.currentUser;
        if (user === null) return courseData.chips;
        
        const chips:string[] = []
        const userEmailDomain = user.email?.split('@')[1];
        const schoolsRef = collection(db, 'schools');
        const q = query(schoolsRef, where("domain", "==", userEmailDomain));

        const userRef = doc(db, 'users/' + user.uid);
        
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                console.log("No matching schools found.");
                return courseData.chips;
            }
            const schoolData = querySnapshot.docs[0].data();
            //console.log("Matching school:", schoolData);

            if (schoolData.offeredCourses.includes(courseData.courseName)) {
                //console.log("courseData.courseName:", courseData.courseName);
                chips.push(pushSchoolOfferChip(true, schoolData.displayName))
            }
            else {
                //console.log("courseData.courseName:", courseData.courseName);
                chips.push(pushSchoolOfferChip(false, schoolData.displayName))
            }
            
            const userRefDocSnap = await getDoc(userRef);
            if (userRefDocSnap.exists()) {
                const courses = userRefDocSnap.data().courses;
                if (courses.includes(courseData.courseName)) {
                    chips.push(pushMyCourseChip(true));
                }
                else {
                    chips.push(pushMyCourseChip(false));
                }
            }

        } 
        catch (e) {
            console.error("Error getting schools:", e);
            return courseData.chips;
        }

        return chips
    }

	const routeChange = (path: string) => {
		navigate(path)
	}

    if (courseFound === null) {
        return (
            <div className="w-full h-full overflow-y-scroll flex justify-start text-gray-700 dark:bg-darkbg dark:text-white">
                <div className="w-full pt-8 ml-[5%] mr-[5%]">
                    <h1 className="text-2xl font-semibold dark:text-darkdecoration underline-offset-4 decoration-4">
                        <span className="underline decoration-contrast dark:decoration-gray-400">Courses</span>
                        {" > "}
                        <span className="underline decoration-contrast dark:decoration-gray-400">{selectedCourse}</span>
                    </h1>
                </div>
            </div>
        )
    }

    else if (courseFound === false) {
        return (
            <div className="w-full h-full overflow-y-scroll flex justify-start text-gray-700 dark:bg-darkbg dark:text-white">
                <div className="w-full pt-8 ml-[5%] mr-[5%]">
                    <h1 className="text-2xl font-semibold dark:text-darkdecoration underline-offset-4 decoration-4">
                        <span className="underline decoration-contrast dark:decoration-gray-400">Courses</span>
                        {" > "}
                        <span className="underline decoration-contrast dark:decoration-gray-400">{selectedCourse}</span>
                        <div className="">
                            <div className="pt-12 w-2/3">
                                <p className="text-md font-semibold">Course not indexed</p>
                                <p className="text-sm font-normal pt-2">This course has not been indexed yet, sorry! We are working to get all courses indexed as soon as possible.</p>
                            </div>
                            <div className="flex items-center justify-center pt-24"><Robot className="w-1/5 h-1/5"/></div>
                            <div className="pt-24 md:w-fit flex-wrap space-x-4">
                                <button onClick={() => {routeChange(`/`)}} className="duration-150 w-28 md:w-40 px-6 py-3 rounded-lg bg-flat hover:bg-primary shadow-md font-semibold text-xl disabled:opacity-40">Dashboard</button>
                                <button onClick={() => {navigate(-1)}} className="duration-150 w-28 md:w-40 px-6 py-3 rounded-lg bg-neutral hover:bg-contrast shadow-md font-semibold text-xl">Go back</button>
                            </div>
                        </div>
                    </h1>
                </div>
            </div>
        )
    }

    else {
        return (
            <div className="w-full h-full overflow-y-scroll flex justify-start text-gray-700 dark:bg-darkbg dark:text-white">
                <div className="w-full pt-8 ml-[5%] mr-[5%]">
                    <h1 className="text-2xl font-semibold dark:text-darkdecoration underline-offset-4 decoration-4">
                        <span className="underline decoration-contrast dark:decoration-gray-400">Courses</span>
                        {" > "}
                        <span className="underline decoration-contrast dark:decoration-gray-400">{courseData.courseName}</span>
                    </h1>
    
                    <div className="pt-4 flex gap-2 font-medium text-sm">
                        {courseData.chips.map((course, index) => {
                            return (
                                <span key={index} className={"flex gap-2 dark:bg-darktan dark:text-text rounded-lg px-4 py-2 " + (course.includes("*yes*") || course.includes("*no*") ? 'bg-flat' : 'bg-secondary ')}>
                                <span className="break-normal flex items-center">
                                    {course.includes("*yes*") ? (
                                        <span className="flex items-center gap-2">
                                        {course.replace("*yes*", "")}
                                        <CheckIcon className="w-6 text-teal-800" />
                                        </span>
                                    ) : course.includes("*no*") ? (
                                        <span className="flex items-center gap-2">
                                        {course.replace("*no*", "")}
                                        <XMarkIcon className="w-6 text-red-800" />
                                        </span>
                                    ) : (
                                        <span className="flex items-center">{course}</span>
                                    )}
                                    </span>
                                </span>
                            );
                        })}
                    </div>
    
                    <div className="pt-8 break-normal w-5/6 font-light">
                        <span>{courseData.description}</span>
                        <div>
    
                        </div>
                        {/* <br />
                        <span className="italic text-xs">© NESA, {new Date().getFullYear()}</span> */}
                    </div>
    
                    <hr className="mt-8"></hr>
    
                    <div className="pt-4">
                        <div className="w-full relative">
                            <div className="absolute w-full ">
                                <div className="overflow-x-auto flex gap-6 pt-4 pb-4 pl-1 min-width-max pr-[200px]">
                                    <div
                                        className="w-[270px] p-4 bg-flatlight dark:bg-darkflat dark:text-darkdecoration rounded-md shadow-md flex-none cursor-pointer hover:bg-flat dark:hover:bg-darkhover duration-200"
                                        onClick={() => {
                                            window.open(courseData.syllabusLink);
                                        }}
                                    >
                                        <div className="flex">
                                            <span className="text-2xl font-semibold w-full">Syllabus</span>
                                            <ArrowTopRightOnSquareIcon className="w-8 mr-1" />
                                        </div>
                                        <span className="font-light text-sm dark:text-white text-gray-700">Jump to the latest syllabus.</span>
                                    </div>
                                    {!courseData.courseSpecs ? (
                                        null // don't render anything if courseSpecs doesn't exists
                                    ) : (
                                        <div
                                        className="w-[270px] p-4 bg-flatlight dark:bg-darkflat dark:text-darkdecoration rounded-md shadow-md flex-none cursor-pointer hover:bg-flat dark:hover:bg-darkhover duration-200 "
                                        onClick={() => {
                                            window.open(courseData.courseSpecs);
                                        }}
                                        >
                                        <div className="flex">
                                            <span className="text-2xl font-semibold w-full">Course Specs</span>
                                            <ArrowTopRightOnSquareIcon className="w-8 mr-1" />
                                        </div>
                                        <span className="font-light text-sm dark:text-white text-gray-700">
                                            Jump to the {courseData.courseCode} course specifications.
                                        </span>
                                        </div>
                                    )}
                                    <div
                                        className="w-[270px] p-4 bg-flatlight dark:bg-darkflat dark:text-darkdecoration rounded-md shadow-md flex-none cursor-pointer hover:bg-flat dark:hover:bg-darkhover duration-200"
                                        onClick={() => {
                                            goToFinder(courseData.courseName);
                                        }}
                                    >
                                        <div className="flex">
                                            <span className="text-2xl font-semibold w-full">{courseData.courseCode} Questions</span>
                                            <MagnifyingGlassIcon className="w-8 mr-1" />
                                        </div>
                                        <span className="font-light text-sm dark:text-white text-gray-700">Open {courseData.courseCode} on the Finder page.</span>
                                    </div>
                                    <div
                                        className="w-[270px] p-4 bg-flatlight dark:bg-darkflat dark:text-darkdecoration rounded-md shadow-md flex-none cursor-pointer hover:bg-flat dark:hover:bg-darkhover duration-200"
                                        onClick={() => {
                                            goToPapers(courseData.courseName);
                                        }}
                                    >
                                        <div className="flex">
                                            <span className="text-2xl font-semibold w-full">{courseData.courseCode} Papers</span>
                                            <DocumentChartBarIcon className="w-8 mr-1" />
                                        </div>
                                        <span className="font-light text-sm dark:text-white text-gray-700">Open the past papers for {courseData.courseCode}.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="mt-44 ">
                        <div className="w-full flex flex-col xl:flex-row gap-2 h-3/5 pt-2 pb-6">
                            <div className="w-full xl:w-3/4 3xl:w-2/3 ">
                                <div className="w-full 2xl:w-[90%]">
                                    <span className="text-xl font-semibold underline underline-offset-4 decoration-primary decoration-2">Latest {courseData.courseCode} Worksheets</span>
                                    <div className="bg-gray-100 shadow-md rounded-md">{worksheetTableData}</div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="pt-8">
                        <h1 className="text-xl font-semibold underline underline-offset-4 decoration-primary decoration-2 w-full">NESA Info</h1>
                        <div className="pt-4">
                            <div className="pb-3">
                                <span className="font-medium">Prerequisites: </span>
                                <span>{courseData.prerequisites}</span>
                            </div>
                            <div className="pb-3">
                                <span className="font-medium">Corequisites: </span>
                                <span>{courseData.corequisites}</span>
                            </div>
                            <div className="pb-3">
                                <span className="font-medium">Eligibility: </span>
                                <span>{courseData.eligibility}</span>
                            </div>
                            <div className="pb-3">
                                <span className="font-medium">Self-tuition: </span>
                                <span>{courseData.selfTuition}</span>
                            </div>
                            <div className="pb-3">
                                <span className="font-medium">Exclusions: </span>
                                <ul>
                                    {courseData.exclusions.map((exclusion, index) => (
                                    <li key={index} className="pl-8">• {exclusion}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="pb-3">
                                <span className="font-medium">Learnings: </span>
                                {courseData.learnings.map((learning, index) => (
                                    <div key={index} className="pl-8">
                                        <span className="font-medium">• {learning.title}</span>
                                        <ul className="pl-8">
                                            {learning.subtopics.map((subtopic, index) =>
                                            typeof subtopic === "string" ? (
                                                <li key={index}>- {subtopic}</li>
                                            ) : (
                                                <li key={index} className={`pl-${8*subtopic.indentLevel}`}>
                                                - {subtopic.text}
                                                </li>
                                            )
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <div className="pt-8">
                                <span className="flex items-center justify-start gap-2 cursor-pointer" onClick={() => {window.open(courseData.nesaPageLink)}}>
                                    <h3 className="pt-1 text-md font-semibold underline underline-offset-2 decoration-darkgreen decoration-2">Learn More</h3>
                                    <ArrowTopRightOnSquareIcon className="w-6" />
                                </span>
                            </div>
                        </div>
                    </div>
    
    
    
                    <div className="pt-48">
    
                    </div>
                </div>
            </div>
        );
    }
	
};

export default Course;
