/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useEffect } from "react";

// set a global array containing all stored refs
const darkModeRefs: RefObject<HTMLDivElement>[] = [];

let scheme: { current: "light" | "dark" } = { current: "light" };

const getCurrentScheme = (update?: (val: "light" | "dark") => void) => {
	return new Proxy(scheme, {
		set: (target, key, val) => {
			target.current = val;
			if (update) update(target.current);
			return true;
		},
	});
};

// export const useCurrentScheme = () => {
// 	const [internalScheme, setInternalScheme] = useState<"light" | "dark">();

// 	const update = (val: "light" | "dark") => {
// 		console.log(val);
// 		setInternalScheme(val);
// 	};

// 	getCurrentScheme(update);

// 	return internalScheme;
// };

const switcher = (): "light" | "dark" => {
	if (scheme.current === "light") return "dark";
	else return "light";
};

const includes = (classList: DOMTokenList, scheme: "light" | "dark") => {
	for (let i = 0; i < classList.length; i++) {
		if (classList[i] === scheme) return true;
	}
	return false;
};

//Adding a listener for if system lightmode changes
// matches === true = "dark"
export const changeLightMode = (matches?: boolean, args?: RefObject<HTMLDivElement>[]) => {
	let inputArgs = [];
	if (!args) {
		inputArgs = darkModeRefs;
	} else {
		inputArgs = args;
	}

	let newScheme: "light" | "dark" = "light";
	if (matches === true) newScheme = "dark";
	else if (matches === false) newScheme = "light";
	else newScheme = switcher();

	inputArgs.forEach((ref) => {
		if (ref.current && newScheme !== scheme.current && !includes(ref.current.classList, newScheme)) {
			ref.current.classList.add(newScheme);
			ref.current.classList.remove(scheme.current);
		}
	});

	getCurrentScheme().current = newScheme;
};

const useDarkMode = (...args: RefObject<HTMLDivElement>[]) => {
	useEffect(() => {
		// Initially set dark mode from system settings
		args.forEach((ref) => {
			darkModeRefs.push(ref);
			if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches && ref.current) {
				ref.current.classList.add("dark");
				getCurrentScheme().current = "dark";
			} else if (ref.current) {
				ref.current.classList.add("light");
			}
		});

		window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (event) => {
			changeLightMode(event.matches, args);
		});

		return function cleanup() {
			window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", (event) => {
				changeLightMode(event.matches, args);
			});

			args.forEach((ref) => {
				const index = darkModeRefs.indexOf(ref);
				darkModeRefs.splice(index);
			});
		};
	}, []);

	return () => {
		args.forEach((ref) => {
			if (ref.current && !includes(ref.current.classList, scheme.current)) {
				ref.current.classList.add(scheme.current);
				ref.current.classList.remove(switcher());
			}
		});
	};
};

export default useDarkMode;
