import { LockClosedIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { getAuth, OAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithRedirect } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { getAnalytics, logEvent } from "firebase/analytics";
import { toastOptions } from "../utilities/utilities";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { Query, collection, getDocs, getFirestore } from "firebase/firestore";
import { ga4react } from "../../Tracking";

type SchoolType = {
	domain: string;
	provider: string;
	displayName?: string;
	school?: string;
};

const Login = () => {
	const [emailInput, setEmailInput] = useState<string>("");
	const [passwordInput, setPasswordInput] = useState<string>("");
	const [emailPassVisible, SetEmailPassVisible] = useState<"email" | "pass">("email");

	const [passwordReset, setPasswordReset] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [schools, setSchools] = useState<{ [key: string]: SchoolType }>({});

	const passwordRef = useRef<HTMLInputElement>(null);

	const navigate = useNavigate();
	const auth = getAuth();
	const db = getFirestore();
	const analytics = getAnalytics();

	const getSchools = async () => {
		const q: Query = collection(db, "schools/");
		const output: { [key: string]: SchoolType } = {};
		const docs = await getDocs(q);

		docs.forEach((doc) => {
			output[doc.data()["displayName"]] = doc.data() as SchoolType;
		});

		setSchools(output);
	};

	useEffect(() => {
		document.title = "HSC Buddy | Login";
	})

	useEffect(() => {
		getSchools();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (emailPassVisible === "pass" && passwordRef.current) {
			passwordRef.current.focus();
		}
	}, [emailPassVisible])

	const login = async () => {
		if (emailPassVisible === "email") {
			const emailSplit = emailInput.split("@");
			const emailDomain = emailSplit[emailSplit.length - 1];

			for (let schoolIndex in Object.keys(schools)) {
				const school = schools[Object.keys(schools)[schoolIndex]];
				if (school.domain === emailDomain) {
					const provider = new OAuthProvider(school.provider);
					console.log("redirect");
					const ga = await ga4react;
					ga.event("login-redirect", "login-with-microsoft", "microsoft");

					signInWithRedirect(auth, provider);

					// sign in with redirect will leave this page, this is just a failsafe if code gets executed before that happens
					return;
				}
			}

			fetchSignInMethodsForEmail(auth, emailInput)
				.then((result) => {
					if (result.includes("password")) {
						SetEmailPassVisible("pass");
					} else {
						toast.error("User not signed up with email and password");
					}
				})
				.catch((err) => {
					toast.error(err.code.replace("auth/", "").replaceAll("-", " "), toastOptions);
				});
		} else {
			setIsLoading(true);
			signInWithEmailAndPassword(auth, emailInput, passwordInput)
				.then(async () => {
					navigate("/");
					logEvent(analytics, "login", {
						method: "EmailPass",
					});
					const ga = await ga4react;
					ga.event("login", "login-submit", emailInput.split("@")[0]);
				})
				.catch((err) => {
					SetEmailPassVisible("email");
					setIsLoading(false);
					toast.error(err.code.replace("auth/", "").replaceAll("-", " "), toastOptions);
				});
		}
	};

	const resetPassword = () => {
		setIsLoading(true);
		sendPasswordResetEmail(auth, emailInput)
			.then(() => {
				setIsLoading(false);
				setPasswordReset(false);
				toast.success("Email Sent!", toastOptions);
			})
			.catch((err) => {
				setIsLoading(false);
				toast.error(err.code.replace("auth/", "").replaceAll("-", " "), toastOptions);
			});
	};

	const handleLoginChange = (key: string, source: "email" | "password") => {
		if (!passwordRef.current) return;
		if (key === "Enter") {
			source === "email" ? passwordRef.current.focus() : login();
		}
	};

	const handleResetPasswordChange = (key: string) => {
		if (key === "Enter") resetPassword();
	};

	if (isLoading) {
		return (
			<div className="w-full h-full flex flex-col justify-around items-center">
				<SyncLoader color="#5D8AA8" />
			</div>
		);
	}


	return (
		<div className="w-full h-full flex flex-col justify-center items-center bg-gradient-to-r from-contrast to-neutral text-text">
			<span className="text-3xl font-bold pb-12">
				Welcome to <span className="underline underline-offset-4 decoration-4 decoration-primary">HSC Buddy</span>
			</span>
			<span className="text-[64px] font-bold">👋</span>
			<div className="rounded-lg px-5 sm:px-20 py-20 flex flex-col items-center gap-5 relative">
				<div className={"items-center gap-3 relative " + (emailPassVisible === "email" ? "flex" : "hidden")}>
					<UserCircleIcon className="w-6 h-6 absolute -left-8 bottom-2" />
					<div className="flex flex-col">
						<span className="text-xs">Email</span>
						<input
							type="email"
							onKeyUp={(evt) => {
								passwordReset ? handleResetPasswordChange(evt.key) : handleLoginChange(evt.key, "email");
							}}
							onChange={(evt) => setEmailInput(evt.target.value)}
							onKeyDown={(evt) => {
								if (evt.key === "Enter") {
									passwordReset ? resetPassword() : login();
								}
							}}
							value={emailInput}
							placeholder="me@example.com"
							className="bg-flatlight focus:bg-flat px-2 py-2 rounded-lg !outline-none border-transparent focus:border-transparent focus:ring-0 disabled:bg-slate-200 disabled:text-gray-400 shadow-lg"
						/>
					</div>
				</div>
				<div className={"items-center gap-3 relative " + (passwordReset || emailPassVisible !== "pass" ? "hidden" : "flex")}>
					<LockClosedIcon className="w-6 h-6 absolute -left-8 bottom-2" />
					<div className="flex flex-col">
						<span className="text-xs">Password</span>
						<input
							type="password"
							onKeyUp={(evt) => handleLoginChange(evt.key, "password")}
							ref={passwordRef}
							onChange={(evt) => setPasswordInput(evt.target.value)}
							value={passwordInput}
							placeholder=""
							className="bg-flatlight focus:bg-flat px-2 py-2 rounded-lg !outline-none border-transparent focus:border-transparent focus:ring-0 disabled:bg-slate-200 disabled:text-gray-400 shadow-lg"
						/>
					</div>
				</div>
				<button
					className="mt-10 duration-300 w-28 md:w-36 px-6 py-3 rounded-lg bg-flat hover:bg-primary shadow-md font-semibold text-xl disabled:opacity-40"
					onClick={() => {
						passwordReset ? resetPassword() : login();
					}}
				>
					{passwordReset ? "Reset" : emailPassVisible === "email" ? "Submit" : "Login"}
				</button>
				<button className="absolute bottom-5 right-0 p-5 font-light text-sm underline text-text hover:text-black" onClick={() => setPasswordReset(!passwordReset)}>
					{passwordReset ? "log in" : "forgot password"}
				</button>
			</div>
		</div>
	);
};

export default Login;
