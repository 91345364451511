/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useContext, useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { pageData, question } from "./utilities/utilities";
import Table from "./utilities/uicomponents/Table";
import ComboboxInput from "./utilities/uicomponents/ComboboxInput";
import subjectsContext from "./utilities/subjectsContext";
import useWindowDimensions from "./utilities/windowDimensions";
import { useLocation } from "react-router-dom";
import index from "../AlgoliaInit";
import { ArrowTopRightOnSquareIcon, ScaleIcon } from "@heroicons/react/24/outline";

//TODO:
// Make the filter smarter (potentially 3rd party apis)
// Only gets first 20 results for performance reasons needs to be dynamically handled

const Finder = () => {
	const db = getFirestore();

	const [fetchedData, setFetchedData] = useState<{ [key: string]: question }>({});
	const [fetchedIds, setFetchedIds] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const subjects = useContext(subjectsContext);
	const { width, height } = useWindowDimensions();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	// Search Params
	const [chosenSubject, setChosenSubject] = useState<string>("");
	const [prevSubject, setPrevSubject] = useState<string>("");
	const [searchInput, setSearchInput] = useState<string>("");
	const [prevSearch, setPrevSearch] = useState<string>("");
	const [prevPage, setPrevPage] = useState<number>(0);

	// Pagination
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageData, setPageData] = useState<pageData>();
	const [totalResults, setTotalResults] = useState<number>(0);

	useEffect(() => {
		const search = searchParams.get("p");
		setChosenSubject(search !== null ? search : "");
		setPageData(undefined);
		setTotalResults(0);
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		document.title = "HSC Buddy | Finder";
	}, [])

	useEffect(() => {
		setSearchInput("");
	}, [chosenSubject]);

	useEffect(() => {
		if (chosenSubject === "") return;

		searchIndex();
	}, [height, currentPage, chosenSubject]);

	const searchIndex = async () => {
		// TODO: add toast errors
		const rawTableHeight = height - 375;
		const tableHeightAdjusted = Math.ceil(rawTableHeight / 56) * 56;
		const rowsPerPage = Math.ceil(tableHeightAdjusted / 56 - 1);

		let searchQuery = searchInput;

		// Check if everything is the same to reduce queries
		if (currentPage === prevPage && chosenSubject === prevSubject && prevSearch === searchInput && rowsPerPage === pageData?.numRows) return;

		if (chosenSubject !== prevSubject && searchInput !== "") searchQuery = "";

		setPrevPage(currentPage);
		setPrevSearch(searchQuery);
		setPrevSubject(chosenSubject);

		setLoading(true);

		setFetchedData({});

		const res = await index.search(searchQuery, {
			attributesToRetrieve: ["objectID"],
			filters: `subject:"${chosenSubject}"`,
			page: currentPage - 1,
			hitsPerPage: rowsPerPage,
		});

		fetchDataFromFirestore(res.hits.map((val) => val.objectID));

		setPageData({ tableHeight: tableHeightAdjusted, numRows: rowsPerPage, totalResults: res.nbHits, totalPages: res.nbPages });
		setTotalResults(res.nbHits);
	};

	const fetchDataFromFirestore = async (questionIds: string[]) => {
		questionIds.forEach(async (id) => {
			const query = doc(db, "courses/" + chosenSubject + "/questions/" + id);
			const snap = await getDoc(query);

			updateData(id, snap.data());
		});

		setFetchedIds(questionIds);
		setLoading(false);
	};

	const updateData = (id: string, obj?: any) => {
		setFetchedData((prevValue): { [key: string]: question } => {
			const newValue = { ...prevValue };
			newValue[id] = { ...newValue[id], ...obj };
			return newValue;
		});
	};
	const toTitleCase = (str: string) => {
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};

	const getTableData: () => ReactNode[] | "searchEmpty" | "noResult" = () => {
		if (fetchedIds.length === 0 || chosenSubject === "") return "noResult";

		const output = fetchedIds.map((id, i) => {
			if (!fetchedData[id]) return <tr key={i} />;
			const ques: question = fetchedData[id];
			let paper = ques.img;
			let mg = subjects.subjects[chosenSubject][ques.year]["MG"];
			let topics = ques.syllabus;

			if (topics.length > 3) topics = topics.slice(0, 3);

			// TEMP while keywords is a str
			let keywords: string = ques.keywords;
			// let keywords: string = ques.keywords.join(", ");

			for (let i = 1000; i <= 1800; i = i + 200) {
				const maxLength = (i - 1000) / 20 + 10;
				if (width < i && keywords.length >= maxLength) {
					keywords = keywords.slice(0, maxLength - 1) + "...";
				}
			}

			if (keywords.length > 50) {
				keywords = keywords.slice(0, 49) + "...";
			}

			return (
				<tr key={i} className={"h-[56px] overflow-hidden dark:text-white " + (i % 2 === 0 ? "bg-white dark:bg-darktable1 " : "bg-transparent dark:bg-darktable2 ")}>
					<td className="px-6 py-3">
						<span>{toTitleCase(keywords)}</span>
					</td>
					{width > 870 ? <td className="px-6 py-3">{ques.year}</td> : undefined}
					<td className="px-6 py-3">{ques.marks}</td>
					<td className="px-6 py-3">{topics.join(", ")}</td>
					<td className="px-6 flex h-[56px] justify-end gap-2 items-center">
						<a href={paper} target="_blank" rel="noreferrer" className="px-4 py-2 rounded-lg bg-secondary dark:bg-primary dark:hover:opacity-80 duration-100 dark:text-text hover:bg-primary shadow-sm">
							<ArrowTopRightOnSquareIcon className="w-6 h-6" />
						</a>
						<a href={mg} target="_blank" rel="noreferrer" className="px-4 py-2 rounded-lg bg-neutral dark:bg-contrast dark:hover:opacity-80 duration-100 dark:text-text hover:bg-contrast shadow-sm">
							<ScaleIcon className="w-6 h-6" />
						</a>
					</td>
				</tr>
			);
		});

		if (output.length === 0) return "searchEmpty";
		else return output;
	};

	return (
		<div className="w-full h-full relative flex flex-col items-center text-xs sm:text-sm md:text-base lg:text-lg text-gray-700 dark:bg-darkbg dark:text-white">
			<div className="w-5/6 max-w-6xl mt-6 pt-5 px-5 flex flex-col gap-2">
				<span className="font-semibold text-left dark:text-darktext">HSC Question Finder</span>
				<p className="text-sm lg:text-base font-normal text-gray-500 dark:text-white">Browse a list of any question from every HSC past paper in any of your designated subjects!</p>
				<div className="grid pt-2 grid-cols-5 w-full gap-5">
					<ComboboxInput
						placeholder="Chemistry, Physics, ..."
						setComboboxOutput={(subject) => {
							setChosenSubject(subject);
						}}
						options={subjects.mySubjects}
						className="flex justify-start col-span-2"
						comboboxInput={chosenSubject}
					/>
					<div className="flex gap-5 w-full col-span-3 justify-self-end">
						<input
							type="text"
							placeholder="Keywords or Topics"
							onChange={(event) => setSearchInput(event.target.value)}
							value={searchInput}
							onKeyDown={(evt) => {
								if (evt.key === "Enter") {
									setCurrentPage(1);
									searchIndex();
								}
							}}
							className="max-h-12 w-full rounded-md px-6 py-3 bg-flatlight dark:bg-darkgray dark:placeholder:text-gray-400 placeholder:text-gray-500 border-none focus:ring-0 focus:bg-flat "
						/>
						<button
							onClick={() => {
								setCurrentPage(1);
								searchIndex();
							}}
							className="py-2.5 px-6 rounded-md bg-neutral dark:bg-darkdecoration dark:text-text duration-200 dark:hover:opacity-80"
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<Table
				cols={width > 870 ? ["Keywords", "Year", "Marks", "Syllabus", ""] : ["Keywords", "Marks", "Syllabus", ""]}
				tableData={getTableData()}
				loading={loading}
				totalResults={totalResults}
				customPagination={pageData ? { pageData: pageData, pageNum: { currentPage, setCurrentPage } } : undefined}
			/>
		</div>
	);
};

export default Finder;
