import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import App from "./App";
import Dashboard from "./components/Dashboard";
import Papers from "./components/Papers";
import Finder from "./components/Finder";
import AllWorksheets from "./components/worksheets/AllWorksheets";
import NotFound from "./components/utilities/uicomponents/404";
import Course from "./components/courses/Course";
import AllCourses from "./components/courses/AllCourses";

// import Validator from "./components/utilities/uicomponents/validatingQuestions";
import Profile from "./components/profile/Profile";
import Onboarding from "./components/profile/Onboarding";

const firebaseConfig = {
	apiKey: "AIzaSyD658HTAgG3lHpWpYSKq0vqqbn4_do44o4",
	authDomain: "app.hsc-buddy.com",
	projectId: "hsc-question-finder",
	storageBucket: "hsc-question-finder.appspot.com",
	messagingSenderId: "1021723331627",
	appId: "1:1021723331627:web:ed2a3660579b83af98ee6e",
	databaseURL: "https://hsc-question-finder-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);
getAuth(app);
getFirestore(app);
getStorage(app);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<ThemeProvider>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<Dashboard />} />
					<Route path="question-finder" element={<Finder />} />
					<Route path="question-search" element={<Papers />} />
					<Route path="worksheets" element={<AllWorksheets />} />
					<Route path="courses" element={<AllCourses />} />
					<Route path="courses/:course" element={<Course />} />
					<Route path="profile" element={<Profile />} />
					<Route path="onboarding" element={<Onboarding />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</BrowserRouter>
	</ThemeProvider>
);
