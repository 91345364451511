import * as React from "react"
const Robot = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M10.5 6.5 9 7.5l-1.5-1V5L9 4l1.5 1z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M12 .5v1M16 23.5H8L6.5 16 9 13.5h6l2.5 2.5zM12 10.5v3M7.6 21.5h8.8"
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m2.5 16 .5 3 3.844-1.281M.5 12v2l2 2 2-2v-2M16 10.5 20.5 6 16 1.5H8L3.5 6 8 10.5z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m13.5 6.5 1.5 1 1.5-1V5L15 4l-1.5 1z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m21.5 16-.5 3-3.844-1.281M23.5 12v2l-2 2-2-2v-2"
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
)
export default Robot
