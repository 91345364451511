import { ArrowLongRightIcon, ArrowTopRightOnSquareIcon, BeakerIcon, DocumentTextIcon, FireIcon, FolderIcon, LinkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import quotesData from "./utilities/quotes.json";
import { worksheets } from "../AlgoliaInit";
import { worksheetObj } from "./utilities/utilities";

const Dashboard = () => {
	const db = getFirestore();
	const auth = getAuth();
	const navigate = useNavigate();

	const [worksheetDataOrWhatever, setWorksheetDataOrWhatever] = useState<React.ReactElement>(<div></div>);
	const noWorksheets: React.ReactElement = (
		<div className="w-full mt-4 py-4 px-4 rounded-md shadow-md h-full flex justify-start items-center gap-3 text-gray-500 bg-gray-100 dark:bg-darkgray dark:text-white">
			<MagnifyingGlassIcon className="w-8 h-8" />
			<div className="w-fit flex flex-col items-start text-sm lg:text-base font-normal">
				<span>Looks like you dont have any worksheets! </span>
				<button
					onClick={() => {
						navigate('/worksheets?new=true');
					}}
					className="text-contrast underline"
				>
					Make one now
				</button>
			</div>
		</div>
	);

	type Course = {
		name: string;
		code: string;
	};

	const [userName, setUserName] = useState<string>();
	const [myCourses, setMyCourses] = useState<Course[]>([]);

	useEffect(() => {
		document.title = "HSC Buddy | Dashboard";
	}, [])

	const getQuote = () => {
		const quotes = quotesData.quotes;
		const randomIndex = Math.floor(Math.random() * quotes.length);
		const randomQuote = quotes[randomIndex];
		return randomQuote;
	};

	const setInitialUsername = async () => {
		const user = auth.currentUser;
		if (user) {
			const nameRef = doc(db, "users", user.uid);
			const nameSnap = await getDoc(nameRef);
			if (nameSnap.exists()) {
				const name = nameSnap.get("firstname");
				setUserName(name);
			}
		}
	};

	const getInitialCourses = async () => {
		const user = auth.currentUser;
		if (!user) return;

		const userId = user.uid;
		const userRef = doc(db, "users", userId);
		const userSnap = await getDoc(userRef);

		if (userSnap.exists()) {
			const userCourses = userSnap.get("courses");
			const courses: Course[] = [];

			for (const courseName of userCourses) {
				const shortName = await getShortName(courseName);
				if (shortName) {
					courses.push({ name: courseName, code: shortName });
				}
			}
			courses.sort((a, b) => a.name.localeCompare(b.name));
			setMyCourses(courses);
		}
	};

	const goToFinder = (course: string) => {
		const query = course.replace(" ", "%20");
		const url = `/question-finder?p=${query}`;

		navigate(url);
	};

	const getMyCoursesDivs = () => {
		//{worksheetObj[val]["name"]}
		return Object.keys(myCourses).map((val, i) => {
			return (
				<div
					key={i}
					className="w-[250px] p-4 bg-flatlight dark:bg-darkflat dark:text-darkdecoration rounded-md shadow-md flex-none cursor-pointer hover:bg-flat dark:hover:bg-darkhover duration-200"
					onClick={() => {
						goToFinder(myCourses[i].name);
					}}
				>
					<div className="flex">
						<span className="text-2xl font-semibold w-full">{myCourses[i].code}</span>
						<MagnifyingGlassIcon className="w-8 mr-1" />
					</div>
					<span className="font-light text-sm dark:text-white text-gray-700">{myCourses[i].name}</span>
				</div>
			);
		});
	};

	const getWorksheetTableData = (worksheetObj: WorksheetData[]) => {
		return worksheetObj.map((val, i) => {
			return (
				<div key={i} className={"grid grid-cols-5 2xl:grid-cols-6 gap-6 py-4 pr-6 pl-4 dark:text-white font-medium text-gray-800 " + (i % 2 === 0 ? "bg-gray-100 dark:bg-darktable1 " : "bg-gray-50 dark:bg-darktable2 ") + (i === 0 ? "rounded-t-xl " : "rounded-none ") + (i === worksheetObj.length - 1 ? "rounded-b-xl" : "rounded-none")}>
					<div className="flex gap-1 col-start-1 col-span-2 2xl:col-span-3">
						<DocumentTextIcon className="w-6" />
						<span className="justify-center items-center flex">{val["name"]}</span>
					</div>

					<span className="justify-center items-center flex">{val["course"]}</span>
					<span className="col-start-4 xl:col-start-4 2xl:col-start-5">{val["shortDate"]}</span>
					<span className="flex gap-2">
						<button
							onClick={() => {
								window.open(val["publicUrl"], "_blank");
							}}
						>
							<ArrowTopRightOnSquareIcon className="w-6" />
						</button>
						<button className="opacity-20 hover:cursor-not-allowed cursor-not-allowed">
							<LinkIcon className="w-6" />
						</button>
					</span>
				</div>
			);
		});
	};

	const getShortName = async (longName: string): Promise<string> => {
		try {
			const nicknamesRef = doc(db, "courseDescriptions", "codes");
			const nicknamesSnap = await getDoc(nicknamesRef);

			if (nicknamesSnap.exists()) {
				const shortName = nicknamesSnap.get(longName).code;
				if (shortName) {
					return shortName as string;
				} else {
					console.error(`Short name not found for ${longName}`);
					return longName;
				}
			} else {
				console.error(`Nicknames document does not exist`);
				return longName;
			}
		} catch (error) {
			console.error("Error fetching course short name:", error);
			return longName;
		}
	};

	const timestampToShortDate = (timestamp: Timestamp): string => {
		const date = timestamp.toDate();
		const day = date.getDate();
		const month = date.toLocaleString("default", { month: "short" });

		return `${day} ${month}`;
	};

	type WorksheetData = {
		name: string;
		course: string;
		shortDate: string;
		publicUrl: string;
	};

	const fetchDataFromFirestore = async (worksheetIds: string[]): Promise<WorksheetData[]> => {
		const user = auth.currentUser;
		if (user === null) return [];
		const unresolvedReturnObj = worksheetIds.map(async (id, i): Promise<WorksheetData> => {
			const query = doc(db, "worksheets/users/" + user.uid + "/" + id);
			const snap = await getDoc(query);

			const data = snap.data() as worksheetObj;
			const shortName = await getShortName(data.course);

			return { publicUrl: data.publicUrl ?? "", name: data.name, course: shortName, shortDate: timestampToShortDate(data.timestamp ?? new Timestamp(0, 0)) };
		});

		const returnObj = await Promise.all(unresolvedReturnObj);

		return returnObj;
	};

	const getLatestWorksheets = async () => {
		const user = auth.currentUser;
		if (user === null) return;

		try {
			const res = await worksheets.search("", {
				attributesToRetrieve: ["objectID"],
				filters: `userID:"${user.uid}" AND _tags:worksheet`, // Filter out results where the course field is empty
				hitsPerPage: 5,
			});
			if (res.hits.length > 0) {
				try {
					const firestoreData = await fetchDataFromFirestore(res.hits.map((val) => val.objectID));
					setWorksheetDataOrWhatever(<div className="dark:bg-darkbg flex flex-col font-semibold mt-4">{getWorksheetTableData(firestoreData)}</div>);
				}
				catch {
					setWorksheetDataOrWhatever(noWorksheets);
				}
			}
			else {
				setWorksheetDataOrWhatever(noWorksheets);
			}

			
		} catch (error) {
			console.error("Error fetching worksheets:", error);
		}
	};

	useEffect(() => {
		setInitialUsername();
		getLatestWorksheets();
		getInitialCourses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSharedTable = (sharedObj: { [key: string]: { type: string; name: string; sharee: string } }) => {
		return Object.keys(sharedObj).map((val, i) => {
			return (
				<div key={i} className={"grid grid-cols-8 gap-6 py-3 pr-6 pl-4 rounded-sm font-medium dark:text-white text-gray-800 " + (i % 2 === 0 ? "bg-gray-100 dark:bg-darktable1 " : "bg-gray-50 dark:bg-darktable2 ") + (i === 0 ? "rounded-t-xl " : "rounded-none ") + (i === Object.keys(sharedObj).length - 1 ? "rounded-b-xl" : "rounded-none")}>
					<div className="flex gap-2 items-center col-span-3">
						{sharedObj[val]["type"] === "worksheet" ? <DocumentTextIcon className="w-6" /> : <FolderIcon className="w-6" />}
						<span>{sharedObj[val]["name"]}</span>
					</div>

					<div className="col-span-3 flex items-center">
						<span>{sharedObj[val]["sharee"]}</span>
					</div>
					<span className="flex gap-2 col-start-8">
						<button className="cursor-not-allowed">
							<ArrowLongRightIcon className="w-8" />
						</button>
					</span>
				</div>
			);
		});
	};

	const tempgetSharedWithMe = () => {
		let sharedObj: { [key: string]: { type: string; name: string; sharee: string } } = {
			uifh2378hef: {
				type: "worksheet",
				name: "Bio AT2",
				sharee: "Naomi Linwood",
			},
			ergsegf1: {
				type: "worksheet",
				name: "Music 2 Practice",
				sharee: "Lewis James",
			},
			ergsdgf1: {
				type: "folder",
				name: "Chem Sheets",
				sharee: "Mason Dean",
			},
			ojh1238jd: {
				type: "folder",
				name: "IPT Revision",
				sharee: "Rodney Hobson",
			},
			ojh1231jd: {
				type: "folder",
				name: "PDHPE Study",
				sharee: "Liz Clark",
			},
		};
		return <div className="flex flex-col font-semibold shadow-md bg-gray-100 dark:bg-darkbg mt-4 ">{getSharedTable(sharedObj)}</div>;
	};

	return (
		<div className="w-full h-full pl-20 pt-16 pr-8 overflow-y-auto dark:bg-darkbg dark:text-darktext">
			<div>
				<div>
					<span className="text-3xl font-semibold dark:text-darkdecoration">Welcome back, {userName}.</span>
					<hr className="w-[400px] mt-2 dark:border-1 dark:border-darkgray"></hr>
					<div className="pt-2">
						<span className="font-light dark:text-white italic text-sm">{getQuote()}</span>
					</div>
				</div>

				<div className="flex flex-row gap-4 dark:text-text  ">
						<button
							className="flex gap-2 mt-4 bg-contrast dark:hover:opacity-70 rounded-md w-fit px-2 py-2 hover:shadow-lg duration-200"
							onClick={() => {
								window.open("https://forms.gle/2AAr9aCoxts9N9Kd7", "_blank");
							}}
						>
							<span className="text-md ">Submit a bug report</span>
							<FireIcon className="w-6 h-6 " style={{ strokeWidth: 1 }} />
						</button>
						<button
							className="flex gap-2 mt-4 bg-flat dark:bg-[#79A678] dark:hover:opacity-70 rounded-md w-fit px-2 py-2 hover:shadow-lg duration-200"
							onClick={() => {
								window.open("https://forms.gle/rDtHXhLXytYfgzMw5", "_blank");
							}}
						>
							<span className="text-md ">Submit a feature request</span>
							<BeakerIcon className="w-6 h-6 " style={{ strokeWidth: 1 }} />
						</button>
					</div>

				<div className="w-full h-full flex flex-col gap-2 pt-6">
					<div className="w-full h-1/5 mb-20 pb-10 relative">
						{/* <span className="text-lg font-semibold">Your Courses</span>
						<hr className="w-[125px]"></hr> */}
						<div className="absolute w-full ">
							<div className="overflow-x-auto flex gap-6 pt-4 pb-4 pl-1 min-width-max pr-[200px]">{getMyCoursesDivs()}</div>
						</div>
					</div>
					<div className="w-full flex flex-col xl:flex-row gap-2 h-3/5 pt-24 pb-24">
						<div className="w-full xl:w-1/2 ">
							<div className="w-full xl:w-[90%]">
								<span className="text-lg font-semibold underline underline-offset-4 decoration-primary decoration-2">Latest Worksheets</span>
								<div className="bg-gray-100 shadow-md rounded-md">{worksheetDataOrWhatever}</div>
								{/* {tempgetLatestWorksheets()} */}
							</div>
						</div>
						<div className="w-full pt-12 xl:pt-0 xl:w-1/2">
							<div className="w-full xl:w-[90%] opacity-30 hover:cursor-not-allowed">
								<span className="text-lg font-semibold underline underline-offset-4 decoration-primary decoration-2">Shared with me</span>
								{tempgetSharedWithMe()}
							</div>
						</div>
					</div>


					
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
