const subjects = [
	"Biology",
	"Software Design and Development",
	"Business Studies",
	"Chemistry",
	"Design and Technology",
	"Earth and Environmental Science",
	"Economics",
	"Food Technology",
	"French Continuers",
	"Geography",
	"Industrial Technology",
	"Information Processes and Technology",
	"Legal Studies",
	"Mathematics Advanced",
	"Mathematics Extension 1",
	"Mathematics Extension 2",
	"Mathematics Standard 1",
	"Mathematics Standard 2",
	"Physics",
	"Textiles and Design",
];

export default subjects;
