/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ReactNode, useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { getMinMaxQuestionsToDisplay, pageData } from "../utilities";
import useWindowDimensions from "../windowDimensions";
import Pagination from "./Pagination";

type props = {
	cols?: string[];
	colsData?: JSX.Element[] | JSX.Element;
	customHeader?: boolean;
	sorting?: { sorting: string; setSorting: (val: string) => void };
	tableData: ReactNode[] | "searchEmpty" | "noResult";
	setDisplayNum?: (val: [min: number, max: number]) => void;
	totalResults: number;
	loading: boolean;
	numFolders?: number;
	notFull?: React.ReactElement;
	noData?: React.ReactElement;
	searchEmpty?: React.ReactElement;
	customPagination?: { pageData: pageData; pageNum: { currentPage: number; setCurrentPage: (page: number) => void } };
};

const Table = (props: props) => {
	const { height } = useWindowDimensions();
	const [internalCurrentPage, setInternalCurrentPage] = useState<number>(1);
	const { numFolders, customHeader, colsData, cols, sorting, tableData, setDisplayNum, totalResults, notFull, noData, loading, searchEmpty, customPagination } = props;
	const [internalPageData, setInternalPageData] = useState<pageData>({ tableHeight: 0, totalPages: 0, totalResults: totalResults, numRows: 0 });

	useEffect(() => {
		if (!customPagination) {
			getTableDetails();
			setInternalCurrentPage(1);
		}
	}, [height, totalResults, numFolders]);

	useEffect(() => {
		if (customPagination && setDisplayNum) {
			setDisplayNum(getMinMaxQuestionsToDisplay(customPagination.pageData, customPagination.pageNum.currentPage));
		} else if (setDisplayNum) {
			setDisplayNum(getMinMaxQuestionsToDisplay(internalPageData, internalCurrentPage));
		}
	}, [internalPageData, customPagination?.pageData, internalCurrentPage, customPagination?.pageNum.currentPage]);

	const updatePageData = (data: Array<[number, "totalResults" | "totalPages" | "tableHeight" | "numRows"]>) => {
		setInternalPageData((prevValue): pageData => {
			const newValue = { ...prevValue };
			data.forEach((i) => {
				newValue[i[1]] = i[0];
			});
			return newValue;
		});
	};

	const getTableDetails = () => {
		const rawTableHeight = height - 375;
		const tableHeightAdjusted = Math.ceil(rawTableHeight / 56) * 56;
		const rowsPerPage = tableHeightAdjusted / 56 - 1;
		const totalPages = totalResults / rowsPerPage;

		updatePageData([
			[tableHeightAdjusted, "tableHeight"],
			[rowsPerPage, "numRows"],
			[totalResults, "totalResults"],
			[totalPages === Infinity ? 0 : Math.ceil(totalPages), "totalPages"],
		]);
	};

	const renderContent = () => {
		const noResults = noData ?? (
			<div className="w-1/2 h-full flex justify-center items-center gap-3 text-gray-500">
				<MagnifyingGlassIcon className="w-12 h-12 dark:text-darktext" />
				<div className="w-fit text-sm lg:text-base font-normal dark:text-darktext">
					<span>No results found! </span>
				</div>
			</div>
		);

		const tableSearchEmpty = searchEmpty ?? (
			<div className="w-1/2 h-full flex justify-center items-center gap-3 text-gray-500">
				<MagnifyingGlassIcon className="w-12 h-12" />
				<div className="w-fit text-sm lg:text-base font-normal">
					<span>Search has no results!</span>
				</div>
			</div>
		);

		const tableNotFull = notFull ?? <div className="text-sm lg:text-base font-normal w-1/2 h-full flex justify-center items-center text-gray-500">No more results.</div>;

		if (loading)
			return (
				<div className="w-1/2 h-full flex flex-col justify-around items-center">
					<SyncLoader color="#CCD5AE" />
				</div>
			);
		else if (tableData === "noResult") return noResults;
		else if (tableData === "searchEmpty") return tableSearchEmpty;
		else if (customPagination && tableData.length < customPagination.pageData.numRows) return tableNotFull;
		else if (tableData.length < internalPageData.numRows) return tableNotFull;
	};

	const renderHeader = () => {
		if (!customHeader && cols) {
			return cols.map((val, i) => (
				<th key={i} scope="col" className="py-3 text-center px-6">
					<div className="w-full">
						<button
							className={"flex items-center justify-center gap-1 " + (sorting && sorting.sorting === val ? "underline underline-offset-4 decoration-2 decoration-contrast" : "")}
							onClick={() => {
								if (sorting && val) sorting.setSorting(val);
							}}
						>
							<span>{val}</span>
							{sorting && val ? <ChevronDownIcon className="w-5 h-5" /> : null}
						</button>
					</div>
				</th>
			));
		} else if (customHeader) {
			return colsData;
		}
	};

	return (
		<div className="h-full w-full flex flex-col items-center justify-center text-gray-700">
			<div
				className="w-5/6 max-w-6xl flex flex-col items-center bg-gray-100 dark:bg-darktable2 dark:text-white rounded-md shadow-md flex-none"
				style={customPagination ? { height: customPagination.pageData.tableHeight + "px" } : { height: internalPageData.tableHeight + "px" }}
			>
				<table className="w-full table-auto">
					<thead className="uppercase font-medium text-base text-center h-[56px]">
						<tr>{renderHeader()}</tr>
					</thead>
					<tbody>{typeof tableData !== "string" ? tableData : <tr />}</tbody>
				</table>
				{renderContent()}
			</div>
			<Pagination
				pageData={customPagination ? customPagination.pageData : internalPageData}
				currentPage={customPagination ? customPagination.pageNum.currentPage : internalCurrentPage}
				setCurrentPage={(page) => {
					if (customPagination) {
						customPagination.pageNum.setCurrentPage(page);
					} else {
						setInternalCurrentPage(page);
					}
				}}
			/>
		</div>
	);
};

export default Table;
