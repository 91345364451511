/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Popover } from "@headlessui/react";
import { ArrowDownTrayIcon, CloudArrowDownIcon, DocumentTextIcon, EllipsisVerticalIcon, FolderIcon, LinkIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { Id, toast } from "react-toastify";
import Loader from "../utilities/uicomponents/CircleLoader";
import { handleBlur, toastOptions, updateStateArray } from "../utilities/utilities";

type props = {
	rowKey: string | number;
	bgColour: string;
	name: string;
	subjectName: string;
	time: string;
	loading: boolean;
	id: string;
	checkboxesVisible: boolean;
	progress?: number;
	folder?: boolean;
	selected?: { val: string[]; update: (value: React.SetStateAction<string[]>) => void };
	setSharePromptOpen?: (value: React.SetStateAction<boolean>) => void;
	download?: (id: string) => void;
	deleteWorksheet?: (id: string) => void;
	uploadRename: (newName: [string, string], toastId: Id) => void;
	openFolder?: (name: string) => void;
};

const WorksheetRow = (props: props) => {
	// ID and value for current rename popover
	const [currentRename, setCurrentRename] = useState<[string, string]>(["", ""]);
	// Ref used for eventlistener which doesnt get the current val
	const currentRenameRef = useRef<[string, string]>(["", ""]);
	const { rowKey, folder, uploadRename, openFolder, setSharePromptOpen, download, deleteWorksheet, selected, name, bgColour, id, subjectName, time, loading, progress } = props;

	useEffect(() => {
		return () => {
			window.removeEventListener("keydown", finishRename);
		};
	}, []);

	const startRename = async (id: string) => {
		setCurrentRename([id, ""]);

		window.addEventListener("keydown", finishRename);
	};

	const finishRename = (ev?: KeyboardEvent) => {
		if (folder) return;

		let renameInput = currentRename;
		if (ev && ev.key === "Enter") renameInput = currentRenameRef.current;
		else if (ev && ev.key === "Escape") {
			window.removeEventListener("keydown", finishRename);
			setCurrentRename(["", ""]);
			return;
		} else if (ev) return;

		if (renameInput[1] === "") {
			toast.error("Input must not be empty", toastOptions);
			setCurrentRename(["", ""]);
			return;
		}
		const toastId = toast.loading("Renaming worksheet", toastOptions);

		window.removeEventListener("keydown", finishRename);

		setCurrentRename(["", ""]);
		uploadRename(renameInput, toastId);
	};

	const renderRename = (name: string, data: string) => {
		if (currentRename[0] !== data)
			return (
				<td className="py-3 px-6">
					{folder && openFolder ? (
						<button className="flex underline-offset-2 underline decoration-2 decoration-contrast" onClick={() => openFolder(name)}>
							<FolderIcon className="w-6 mr-3" />
							{name}
						</button>
					) : (
						<span className="flex">
							<DocumentTextIcon className="w-6 mr-3" />
							{name}
						</span>
					)}
				</td>
			);
		return (
			<td className="px-6 py-3">
				<Popover className="relative w-fit h-fit">
					<span>{name}</span>
					<Popover.Panel static className="absolute top-12 flex gap-2 bg-white dark:bg-darkbg shadow-md rounded-md px-5 py-2 font-light z-10" onBlur={(e: any) => handleBlur(e, "rename", setCurrentRename)}>
						<input
							autoFocus
							type="text"
							value={currentRename[1]}
							onChange={(evt) => {
								setCurrentRename([currentRename[0], evt.target.value]);
							}}
							className="w-44 max-h-12 rounded-md px-6 py-3 dark:bg-darkgray dark:text-white dark:placeholder:text-gray-400 bg-flatlight placeholder:text-gray-500 border-none focus:ring-0 focus:bg-flat"
							maxLength={20}
							placeholder="New Name"
						/>
						<button
							className="bg-secondary dark:bg-darkdecoration dark:text-text hover:opacity-80 duration-100 px-3 py-1 rounded-md hover:bg-primary"
							onClick={() => {
								finishRename();
							}}
						>
							Submit
						</button>
					</Popover.Panel>
				</Popover>
			</td>
		);
	};

	return (
		<tr key={rowKey} className={bgColour}>
			{selected ? (
				<td className="pl-6 py-3 md:hidden lg:flex">
					<input
						type="checkbox"
						className={"rounded-md bg-transparent border-2 dark:border-darktext border-black focus:ring-transparent focus:ring-0 w-5 h-5 " + (props.checkboxesVisible ? "" : "hidden")}
						checked={selected.val.includes(id)}
						onChange={(onChangeData) => {
							selected.update((prevVal) => updateStateArray<string>(id, selected.val, !onChangeData.target.checked));
						}}
					/>
				</td>
			) : (
				<td className="md:hidden lg:flex" />
			)}
			{renderRename(name, id)}
			<td className="px-6 py-3">{subjectName}</td>
			<td className="px-6 py-3 whitespace-nowrap">{time}</td>
			<td className={"flex gap-2 py-3 justify-end relative " + (folder ? "invisible" : "visible")}>
				{!loading ? (
					<button
						onClick={() => {
							if (download) download(id);
						}}
					>
						<CloudArrowDownIcon className="w-8 h-8" />
					</button>
				) : (
					<Loader radius={15} stroke={2} progress={progress ?? 0} />
				)}
				<Menu>
					<Menu.Button>
						<EllipsisVerticalIcon className="w-8 h-8" />
					</Menu.Button>
					<Menu.Items className="absolute top-full flex flex-col items-start bg-white dark:bg-darkbg shadow-md rounded-md px-3 py-2 font-light z-10">
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										if (download) download(id);
									}}
									className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
								>
									<ArrowDownTrayIcon className="w-6 h-6" />
									Download
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										if (selected && setSharePromptOpen) {
											selected.update([id]);
											setSharePromptOpen(true);
										}
									}}
									className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
								>
									<LinkIcon className="w-6 h-6" />
									Share
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										if (deleteWorksheet) deleteWorksheet(id);
									}}
									className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
								>
									<TrashIcon className="w-6 h-6" />
									Delete
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										if (!folder) startRename(id);
									}}
									className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
								>
									<PencilIcon className="w-6 h-6" />
									Rename
								</button>
							)}
						</Menu.Item>
					</Menu.Items>
				</Menu>
			</td>
		</tr>
	);
};

export default WorksheetRow;
