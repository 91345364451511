import { DocumentData, Timestamp } from "firebase/firestore";
import { ToastOptions } from "react-toastify";

export type question =
	| {
			id: string;
			img: string;
			course: string;
			keywords: string[];
			marks: number;
			question: number;
			subq: null | string;
			syllabus: string;
			year: number;
			verified: Boolean;
	  }
	| DocumentData;

export interface paper {
	Paper: string;
	MG: string;
}
export interface papers {
	[key: string]: paper;
}

export type pageData = {
	totalResults: number;
	totalPages: number;
	numRows: number;
	tableHeight: number;
};

export type worksheetObj = {
	course: string;
	maxQuestions: null | number;
	maxTime: null | number;
	name: string;
	publicUrl: null | string;
	state: "running" | "done" | "error";
	timestamp: null | Timestamp;
	topics: string[];
	userId: string;
	worksheetId: string;
	filepath: string;
	progress?: number;
};

export type allSubjects = {
	[key: string]: { [key: number]: { MG: string; Paper: string } };
};

export type subjectContext = {
	setMySubjects: (subjects: string[]) => void;
	subjects: allSubjects;
	mySubjects: string[];
	indexedSubjects: string[];
};

export const toastOptions: ToastOptions<{}> = {
	position: "top-center",
	autoClose: 1000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: false,
	draggable: true,
	progress: undefined,
	theme: "colored",
};

export const getMinMaxQuestionsToDisplay = (pageData: pageData, currentPage: number): [number, number] => {
	const { numRows, totalResults } = pageData;
	const maxResult = numRows * currentPage;

	const minWorksheetDisplayed = totalResults > 0 ? maxResult - (numRows - 1) : 0;
	const maxWorksheetDisplayed = maxResult >= totalResults ? totalResults : maxResult;

	return [minWorksheetDisplayed, maxWorksheetDisplayed];
};

export const updateStateArray = <T>(val: T, prevVal: T[], del?: boolean) => {
	const newList = [...prevVal];

	if (del) {
		const index = newList.indexOf(val);
		newList.splice(index, 1);
	} else {
		newList.push(val);
	}

	return newList;
};

export const updateStateObject = <T>(val: Partial<T>, prevVal: T) => {
	const newData = { ...prevVal };
	return { ...newData, ...val };
};

export const convertListToTitleCase = (list: string[]) => {
	let newList: string[] = [];
	list.forEach((val) => {
		newList.push(toTitleCase(val));
	});
	return newList;
};

export const toTitleCase = (text: string) => {
	var i, j, str, lowers, uppers;
	str = text.replace(/([^\W_]+[^\s-]*) */g, function (text) {
		return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
	});

	// Certain minor words should be left lowercase unless
	// they are the first or last words in the string
	lowers = ["A", "An", "The", "And", "But", "Or", "For", "Nor", "As", "At", "By", "For", "From", "In", "Into", "Near", "Of", "On", "Onto", "To", "With"];
	for (i = 0, j = lowers.length; i < j; i++)
		str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (text) {
			return text.toLowerCase();
		});

	// Certain words such as initialisms or acronyms should be left uppercase
	uppers = ["Id", "Tv"];
	for (i = 0, j = uppers.length; i < j; i++) str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

	return str;
};

export const handleBlur = (e: any, target: string, setCurrentRename?: (val: React.SetStateAction<[string, string]>) => void, setFolderNamingOpen?: (val: React.SetStateAction<boolean>) => void) => {
	const currentTarget = e.currentTarget;

	if (!currentTarget) return;

	requestAnimationFrame(() => {
		if (!currentTarget.contains(document.activeElement)) {
			if (target === "rename" && setCurrentRename) setCurrentRename(["", ""]);
			else if (target === "newfolder" && setFolderNamingOpen) setFolderNamingOpen(false);
		}
	});
};
