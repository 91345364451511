/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ArrowDownIcon, ArrowDownTrayIcon, ArrowLeftOnRectangleIcon, BeakerIcon, ChevronDownIcon, EllipsisVerticalIcon, FireIcon, NoSymbolIcon, PencilIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import subjectsContext from "../utilities/subjectsContext";
import ComboboxInput from "../utilities/uicomponents/ComboboxInput";
import { toast } from "react-toastify";
import { toastOptions, updateStateObject, updateStateArray } from "../utilities/utilities";
import { Menu, Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { ga4react } from "../../Tracking";
import { changeLightMode } from "../utilities/darkMode";

type userData = {
	first: string;
	last: string;
	email: string;
	courses: string[];
	display: string;
	title: string;
};

const Profile = () => {
	// Firebase Details
	const db = getFirestore();
	const auth = getAuth();
	const navigate = useNavigate();

	const [initialUserData, setInitialUserData] = useState<userData>({ first: "", last: "", email: "", courses: [], display: "", title: "" });
	const [updatingUser, setUpdatingUserData] = useState<userData>({ first: "", last: "", email: "", courses: [], display: "", title: "" });

	const [canSubmit, setCanSubmit] = useState<boolean>(false);

	const [chosenSubject, setChosenSubject] = useState<string>("");

	const [isAddingSubject, setIsAddingSubject] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<Boolean>(false);

	const subjects = useContext(subjectsContext);
	// const currentScheme = useCurrentScheme();
	const currentScheme = "dark";

	// useEffect(() => {
	// 	console.log(currentScheme);
	// }, [currentScheme]);

	useEffect(() => {
		document.title = "HSC Buddy | Profile";
	}, [])

	useEffect(() => {
		if (initialUserData.first === "") setUpdatingUserData(initialUserData);
	}, [initialUserData]);

	useEffect(() => {
		setUpdatingUserData((prevVal) => {
			if (chosenSubject === "" || updatingUser.courses.includes(chosenSubject)) return prevVal;
			const newArray = updateStateArray<string>(chosenSubject, prevVal.courses);

			return updateStateObject<userData>({ courses: newArray }, prevVal);
		});
	}, [chosenSubject]);

	useEffect(() => {
		const { first, last, courses } = updatingUser;
		if (first === "" || last === "" || courses.length === 0) setCanSubmit(false);
		else setCanSubmit(true);
	}, [updatingUser]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";
		const docRef = doc(db, "users", userId);
		const document = await getDoc(docRef);

		if (!document.exists()) return;

		const data = document.data();
		setInitialUserData((prevVal) => updateStateObject<userData>({ first: data["firstname"], last: data["lastname"], courses: data["courses"], title: data["title"] }, prevVal));
		setUpdatingUserData((prevVal) => updateStateObject<userData>({ first: data["firstname"], last: data["lastname"], courses: data["courses"], title: data["title"] }, prevVal));
		// Setting email

		if (auth.currentUser !== null) {
			const userEmail = auth.currentUser.email;
			if (userEmail !== null) {
				setInitialUserData((prevVal) => updateStateObject<userData>({ email: userEmail }, prevVal));
				setUpdatingUserData((prevVal) => updateStateObject<userData>({ email: userEmail }, prevVal));
			}
		}
		setInitialUserData((prevVal) => updateStateObject<userData>({ display: ", " + data["firstname"] }, prevVal));
		setUpdatingUserData((prevVal) => updateStateObject<userData>({ display: ", " + data["firstname"] }, prevVal));
	};

	const handleCancel = () => {
		setUpdatingUserData((prevVal) => updateStateObject<userData>(initialUserData, prevVal));
		setIsEditing(false);
		setIsAddingSubject(false);
	};

	const handleSubmit = async () => {
		const toastId = toast.loading("Saving...", toastOptions);
		const { first, last, courses, title } = updatingUser;

		// push the data to firebase
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";

		subjects.setMySubjects(courses);

		try {
			await setDoc(doc(db, "users", userId), {
				firstname: first,
				lastname: last,
				courses: courses,
				title: title,
				onboarded: true,
			});
			toast.update(toastId, { render: "Profile updated!", type: "success", isLoading: false, ...toastOptions });

			const ga = await ga4react;
			const params = {
				firstname: first,
				lastname: last,
				courses: courses.join(", "),
				title: title,
			};
			const paramsJSON = JSON.stringify(params);
			ga.event("profile-updated", "profile-updated", paramsJSON);
		} catch (err) {
			console.log(err);
			toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
			const ga = await ga4react;
			const params = {
				firstname: first,
				lastname: last,
				courses: courses.join(", "),
				title: title,
			};
			const paramsJSON = JSON.stringify(params);
			ga.event("profile-update-failed", "profile-update-failed", paramsJSON);
		}
		setIsEditing(false);
		setIsAddingSubject(false);
	};

	const getHeaderMsg = () => {
		var data: [number, number, string][] = [
				[0, 4, "Good Night"],
				[5, 11, "Good Morning"],
				[12, 17, "Good Afternoon"],
				[18, 24, "Good Evening"],
			],
			hr = new Date().getHours();

		for (var i = 0; i < data.length; i++) {
			if (hr >= data[i][0] && hr <= data[i][1]) {
				return data[i][2];
			}
		}
	};

	return (
		<div className="overflow-y-scroll h-full w-full justify-around items-start px-16 dark:bg-darkbg dark:text-white">
			<div className="pt-12 justify-start">
				<h1 className="text-4xl font-bold pr-8 text-text break-normal dark:text-darkdecoration">
					{getHeaderMsg()}
					{initialUserData.display}.
				</h1>
				<div className="pt-4 pb-2">
					<span>
						Need to check out the{" "}
						<button
							className="decoration-4 hover:text-gray-500 hover:shadow-lg duration-200 underline underline-offset-4 decoration-primary"
							onClick={() => {
								window.open("https://storage.googleapis.com/hsc-question-finder.appspot.com/HSC%20Buddy%20User%20Guide%20v1.1.pdf", "_blank");
							}}
						>
							user guide
						</button>
						?
					</span>
				</div>
				<div className="flex flex-row gap-4">
					<button
						className="flex gap-2 mt-4 bg-neutral dark:bg-darkgray dark:hover:opacity-80 rounded-md w-fit px-2 py-2 hover:shadow-lg duration-200"
						onClick={() => {
							auth.signOut();
						}}
					>
						<span className="text-md ">Sign out</span>
						<ArrowLeftOnRectangleIcon className="w-6 h-6 " style={{ strokeWidth: 1 }} />
					</button>
					{/* <button
						className="items-center justify-center flex mt-4 bg-neutral dark:bg-darkgray px-2 rounded-md text-white"
						onClick={() => {
							changeLightMode();
						}}
					>
						<label className="relative inline-flex items-center cursor-pointer">
							switch for dark mode toggle, not working yet
							<Switch
								checked={currentScheme === "dark"}
								onChange={() => {
									changeLightMode();
								}}
							>
								{({ checked }) => (
									<button className={`${checked ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}>
										<span className="sr-only">Enable dark mode</span>
										<span className={`${checked ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
									</button>
								)}
							</Switch>
							<span className=" text-sm font-medium text-gray-900 dark:text-white">Dark Mode</span>
						</label>
					</button> */}
				</div>
				<div className="flex flex-row gap-4">
					<button
						className="flex gap-2 mt-4 bg-red-300 dark:bg-darktan dark:text-text rounded-md w-fit px-2 py-2 hover:shadow-lg duration-200"
						onClick={() => {
							window.open("https://forms.gle/2AAr9aCoxts9N9Kd7", "_blank");
						}}
					>
						<span className="text-md ">Submit a bug report</span>
						<FireIcon className="w-6 h-6 " style={{ strokeWidth: 1 }} />
					</button>
					<button
						className="flex gap-2 mt-4 bg-green-300 dark:bg-darkgreen dark:text-text rounded-md w-fit px-2 py-2 hover:shadow-lg duration-200"
						onClick={() => {
							window.open("https://forms.gle/rDtHXhLXytYfgzMw5", "_blank");
						}}
					>
						<span className="text-md ">Submit a feature request</span>
						<BeakerIcon className="w-6 h-6 " style={{ strokeWidth: 1 }} />
					</button>
				</div>
			</div>
			<div className="w-full max-w-3xl gap-7 pt-12">
				<div className="flex flex-col">
					<div className="flex w-full justify-between items-center">
						<h2 className="text-2xl font-semibold dark:text-darktext">My Info</h2>
						<button
							onClick={() => {
								setIsEditing(true);
							}}
							className={"flex text-lg gap-2 text-contrast underline underline-offset-2 decoration-2 " + (isEditing ? "hidden" : "")}
						>
							Edit
							<PencilSquareIcon className="w-6 mt-[1px]" />
						</button>
					</div>
					<hr className="w-full mt-1 dark:border-1 dark:border-darkflat" />
				</div>
				<div className="pt-4 flex w-full">
					<div className="w-1/2 flex flex-col px-2">
						<p>First Name</p>
						<input
							className="mt-1 w-full duration-100 bg-flatlight dark:bg-darkgray dark:placeholder:text-gray-400 focus:bg-flat px-4 py-2 rounded-lg !outline-none border-transparent focus:ring-0 disabled:bg-slate-200 disabled:text-gray-400"
							disabled={isEditing ? false : true}
							value={updatingUser.first}
							onChange={(e) => {
								setUpdatingUserData((prevVal) => updateStateObject<userData>({ first: e.target.value }, prevVal));
							}}
						/>
					</div>
					<div className="w-1/2 flex flex-col px-2">
						<p>Last Name</p>
						<input
							className="mt-1 w-full duration-100 bg-flatlight dark:bg-darkgray dark:placeholder:text-gray-400 focus:bg-flat px-4 py-2 rounded-lg !outline-none border-transparent focus:ring-0 disabled:bg-slate-200 disabled:text-gray-400"
							disabled={isEditing ? false : true}
							value={updatingUser.last}
							onChange={(e) => {
								setUpdatingUserData((prevVal) => updateStateObject<userData>({ last: e.target.value }, prevVal));
							}}
						/>
					</div>
				</div>
				<div className="pt-4 px-2">
					<p>Email</p>
					<span className="relative">
						<input
							className="mt-1 w-[48.75%] duration-100 bg-flatlight dark:bg-darkgray dark:placeholder:text-gray-400 focus:bg-flat pl-4 pr-12 py-2 rounded-lg !outline-none border-transparent focus:border-transparent focus:ring-0 disabled:bg-slate-200 disabled:text-gray-400"
							disabled
							value={initialUserData.email}
						></input>
						<NoSymbolIcon className="w-6 text-gray-500 absolute right-3 top-0 hover:text-gray-800 duration-200" />
					</span>
				</div>
				<div className="pt-4 px-2">
					<p>Title</p>
					<span className="relative flex gap-2 ">
						<div className="relative ">
							<Menu>
								<Menu.Button disabled={isEditing ? false : true}>
									<input
										disabled={isEditing ? false : true}
										value={updatingUser.title}
										className={
											"w-[124px] dark:bg-darkgray dark:disabled:bg-darkgray dark:disabled:placeholder:text-gray-400 rounded-lg pl-2 pr-12 hover:cursor-pointer bg-slate-200 px-2 mt-2 py-2 disabled:text-gray-400 " +
											(isEditing ? " disabled:bg-flatlight disabled:text-text" : "")
										}
									></input>
									<ChevronDownIcon className={"absolute top-3 right-2 w-8 h-8 " + (isEditing ? " " : "hidden")} />
								</Menu.Button>

								<Menu.Items className="absolute right-3 top-0 flex flex-col items-start dark:bg-darkflat bg-white shadow-md rounded-md px-3 py-2 font-light z-10">
									<Menu.Item>
										{({ active }) => (
											<button
												onClick={() => {
													setUpdatingUserData((prevVal) => updateStateObject<userData>({ title: "None" }, prevVal));
												}}
												className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
											>
												None
											</button>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<button
												onClick={() => {
													setUpdatingUserData((prevVal) => updateStateObject<userData>({ title: "Mr." }, prevVal));
												}}
												className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
											>
												Mr.
											</button>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<button
												onClick={() => {
													setUpdatingUserData((prevVal) => updateStateObject<userData>({ title: "Ms." }, prevVal));
												}}
												className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
											>
												Ms.
											</button>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<button
												onClick={() => {
													setUpdatingUserData((prevVal) => updateStateObject<userData>({ title: "Mrs." }, prevVal));
												}}
												className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
											>
												Mrs.
											</button>
										)}
									</Menu.Item>
									<Menu.Item>
										{({ active }) => (
											<button
												onClick={() => {
													setUpdatingUserData((prevVal) => updateStateObject<userData>({ title: "Dr." }, prevVal));
												}}
												className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
											>
												Dr.
											</button>
										)}
									</Menu.Item>
								</Menu.Items>
							</Menu>
						</div>
					</span>
				</div>
			</div>
			<div className="w-full max-w-3xl pt-12 pb-12">
				<h2 className="text-2xl font-semibold dark:text-darktext">My Courses</h2>
				<hr className="w-full mt-1 pb-4 dark:border-1 dark:border-darkgray"></hr>
				<div className={isAddingSubject ? "pb-4" : "hidden"}>
					<div className="flex items-center justify-start">
						<ComboboxInput placeholder="Chemistry, Biology, ..." setComboboxOutput={setChosenSubject} options={subjects.indexedSubjects} />
					</div>
				</div>
				<div className="pl-2">
					<div className="pt-2 flex flex-wrap gap-2 items-center justify-start">
						<button
							onClick={() => {
								setIsAddingSubject(true);
							}}
							className={"bg-primary dark:bg-darkdecoration dark:text-text rounded-lg flex-none px-4 py-2 font-semibold " + (isEditing ? "" : "hidden") + " " + (isAddingSubject ? "hidden" : "")}
						>
							New +
						</button>
						{updatingUser.courses.map((course, index) => {
							return (
								<span key={index} className="flex gap-2 bg-secondary dark:bg-darktan dark:text-text rounded-lg px-4 py-2">
									<span className="break-normal">{course}</span>
									<button onClick={() => setUpdatingUserData((prevVal) => updateStateObject<userData>({ courses: updateStateArray<string>(chosenSubject, prevVal.courses, true) }, prevVal))}>
										<TrashIcon className={"w-6 h-6 text-red-700 " + (isEditing ? "" : "hidden")} />
									</button>
								</span>
							);
						})}
					</div>
				</div>
			</div>
			<div className={"flex-wrap space-x-6 " + (isEditing ? "" : "hidden")}>
				<button onClick={handleCancel} className="w-28 md:w-36 px-6 py-3 rounded-lg bg-neutral dark:bg-darktan dark:text-text hover:bg-contrast shadow-md font-semibold text-xl">
					Cancel
				</button>
				<button onClick={handleSubmit} disabled={canSubmit ? false : true} className="duration-300 dark:bg-darkgreen dark:text-text w-28 md:w-36 px-6 py-3 rounded-lg bg-flat hover:bg-primary shadow-md font-semibold text-xl disabled:opacity-40">
					Save
				</button>
			</div>
		</div>
	);
};

export default Profile;
