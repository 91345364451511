/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { ReactNode, useEffect, useState } from "react";
import { getMinMaxQuestionsToDisplay, pageData } from "../utilities";

const Pagination = (props: { currentPage: number; setCurrentPage: (page: number) => void; pageData: pageData }) => {
	const { totalResults } = props.pageData;
	const { currentPage, setCurrentPage } = props;
	const [totalPages, setTotalPages] = useState<number>(props.pageData.totalPages);

	useEffect(() => {
		setTotalPages(props.pageData.totalPages);
	}, [props]);

	const getPaginateNumber = (pos: number): number => {
		const distanceFromCenter = 3 - pos;
		if (currentPage <= 3 || totalPages <= 5) return pos;
		// Making it so it never shows a page higher than the max results
		if (currentPage >= totalPages - 2) return totalPages - 2 - distanceFromCenter;

		return currentPage > 3 ? pos : currentPage - distanceFromCenter;
	};

	const renderPaginate = () => {
		let output: ReactNode[] = [];
		const numChoices = totalPages > 5 ? 5 : totalPages;

		for (let i = 1; i < numChoices + 1; i++) {
			const number = getPaginateNumber(i);

			output.push(
				<button
					key={i}
					onClick={() => {
						setCurrentPage(number);
					}}
					className={"h-10 w-10 border-l border-white dark:border-darkbg " + (currentPage === number ? "bg-secondary dark:bg-darkbg " : "bg-gray-100 dark:bg-darkgray ")}
				>
					{number}
				</button>
			);
		}

		return (
			<div className="flex">
				<button
					onClick={() => {
						setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
					}}
					className="h-10 px-5 dark:bg-darkgray dark:border-darkbg border-white bg-gray-100 rounded-l-lg"
				>
					<ChevronLeftIcon className="w-6 h-6" />
				</button>
				{output}
				<button
					onClick={() => {
						setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1);
					}}
					className="h-10 px-5 dark:bg-darkgray dark:border-darkbg border-l border-white bg-gray-100 rounded-r-lg"
				>
					<ChevronRightIcon className="w-6 h-6" />
				</button>
			</div>
		);
	};

	return (
		<div className="text-small lg:text-base font-normal dark:text-darktext text-gray-500 w-5/6 max-w-6xl flex mb-12 justify-between px-5 py-4 items-center">
			<span>
				Showing{" "}
				<span className="font-medium">
					{getMinMaxQuestionsToDisplay(props.pageData, currentPage)[0]}-{getMinMaxQuestionsToDisplay(props.pageData, currentPage)[1]}
				</span>{" "}
				of <span className="font-medium">{totalResults}</span>
			</span>
			{renderPaginate()}
		</div>
	);
};

export default Pagination;
