/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { ArrowLongRightIcon, BookOpenIcon, CheckIcon, CpuChipIcon, MapIcon, MicrophoneIcon, MinusIcon, PaintBrushIcon, RocketLaunchIcon, TrophyIcon, VariableIcon, WrenchScrewdriverIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { getAuth } from "firebase/auth";
import subjects from "../utilities/indexedSubjects";
import { useNavigate } from "react-router-dom";

const AllCourses = () => {
    interface CourseDescription {
		code: string;
		discipline: string;
	}
    
    const db = getFirestore();
    const auth = getAuth();
    const navigate = useNavigate();

    const [courseDescriptions, setCourseDescriptions] = useState<{ [key: string]: CourseDescription }>({});
    const [sortedCourses, setSortedCourses] = useState<{ [key: string]: string[] }>();
    const [schoolData, setSchoolData] = useState<string[]>();
    const [schoolName, setSchoolName] = useState<string>("");

    const disciplineIcons: Record<string, React.ReactElement> = {
        "English": <BookOpenIcon className="w-6" />,
        "Mathematics": <VariableIcon className="w-6" />,
        "Science": <RocketLaunchIcon className="w-6" />,
        "Technological and Applied Studies": <CpuChipIcon className="w-6" />,
        "Human Society and Its Environment": <MapIcon className="w-6" />,
        "Creative Arts": <PaintBrushIcon className="w-6" />,
        "Personal Development Health and Physical Education": <TrophyIcon className="w-6" />,
        "Languages": <MicrophoneIcon className="w-6" />,
        "Vocational Education and Training": <WrenchScrewdriverIcon className="w-6" />,
    };

    const disciplineOrder = [
        "Technological and Applied Studies",
        "Science",
        "Mathematics",
        "English",
        "Human Society and Its Environment",
        "Creative Arts",
        "Personal Development Health and Physical Education",
        "Languages",
        "Vocational Education and Training"
    ]

    const getAllCourses = async () => {
        const courseDescriptionsRef = doc(db, "courseDescriptions", "codes");
        const courseDescriptionsSnapshot = await getDoc(courseDescriptionsRef);
        if (courseDescriptionsSnapshot.exists()) {
            setCourseDescriptions(courseDescriptionsSnapshot.data());
        }
        //console.log(JSON.stringify(courseDescriptions));
    };

    useEffect(() => {
        document.title = "HSC Buddy | All Courses"
        getAllCourses();
    }, []);

    const sortCoursesByDiscipline = () => {
        const coursesByDiscipline: { [key: string]: string[] } = {};
        // Create a dictionary to hold courses grouped by discipline
        let index = 0;
        disciplineOrder.forEach((discipline) => {
            coursesByDiscipline[discipline] = [];
        });
        
        Object.values(courseDescriptions).forEach((course) => {
            if (course.discipline in coursesByDiscipline) {
                coursesByDiscipline[course.discipline].push(Object.keys(courseDescriptions)[index]);
            }
            index++;
        });
        
        // Sort the courses within each discipline alphabetically
        Object.keys(coursesByDiscipline).forEach((discipline) => {
            coursesByDiscipline[discipline].sort();
        });
        setSortedCourses(coursesByDiscipline);
    };

    useEffect(() => {
        sortCoursesByDiscipline();
    }, [courseDescriptions]);

    const storeSchoolInfo = async () => {
        const user = auth.currentUser;
        if (user === null) return;
        const userEmailDomain = user.email?.split('@')[1];
        const schoolsRef = collection(db, 'schools');
        const q = query(schoolsRef, where("domain", "==", userEmailDomain));
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                console.log("No matching schools found.");
                return;
            }
            const schoolData = querySnapshot.docs[0].data().offeredCourses;
            setSchoolData(schoolData);
            setSchoolName(querySnapshot.docs[0].data().displayName)
        }
        catch {
            return;
        }
    }

    useEffect(() => {
        storeSchoolInfo();
    }, [])

    const getSchoolOfferedIcon = (course: string) => {
        if (schoolData) {
            if (schoolData.includes(course)) {
                return (
                    <CheckIcon className="w-6"/>
                )
            }
            else {
                return (
                    <XMarkIcon className="w-6"/>
                )
            }
        }
        else return <MinusIcon className="w-6"/>;
    }

    const getHSCBuddyOfferedIcon = (course: string) => {
        if (subjects.includes(course)) {
            return (
                <CheckIcon className="w-6"/>
            )
        }
        else {
            return (
                <XMarkIcon className="w-6"/>
            )
        }
    }
    

    const getDisciplineTable = (discipline: string) => {
        if (sortedCourses && sortedCourses[discipline]) {
            const headerRow = (
                <div id={"header"} className="grid grid-cols-6 2xl:grid-cols-7 gap-6 pb-1 pt-4 pr-6 pl-4 dark:text-white font-bold underline decoration-contrast decoration-2 underline-offset-2 text-gray-800 rounded-t-xl">
                    <div className="flex gap-1 col-start-1 col-span-2 2xl:col-span-3">
                        <span className="justify-center items-center flex">Course</span>
                    </div>
            
                    <span className="col-span-1 flex items-cente justify-center">Code</span>
                    <span className="flex justify-center items-center gap-2">{schoolName}</span>
                    <span className="flex justify-center items-center gap-2">Indexed</span>
                </div>
            );
      
            const courseRows = sortedCourses[discipline].map((val, i) => {
                return (
                    <div 
                        key={i} 
                        className={
                            "grid grid-cols-6 2xl:grid-cols-7 gap-6 py-4 pr-6 pl-4 dark:text-white font-medium text-gray-800 cursor-pointer hover:bg-gray-200 duration-100 " 
                            + (i % 2 === 0 ? "bg-gray-100 dark:bg-darktable1 " : "bg-gray-50 dark:bg-darktable2 ") 
                            + (i === 0 ? "rounded-t-xl " : "rounded-none ") 
                            + (i === sortedCourses[discipline].length - 1 ? "rounded-b-xl" : "rounded-none")
                        }
                        onClick={() => {
                            navigate(`/courses/${val}`);
                        }}
                    >
                        <div className="flex gap-1 col-start-1 col-span-2 2xl:col-span-3">
                            <span className="justify-center items-center flex">{val}</span>
                        </div>
            
                        <span className="col-span-1 flex justify-center">{courseDescriptions[val]['code']}</span>
                        <span className="col-span-1 flex justify-center">{getSchoolOfferedIcon(val)}</span>
                        <span className="col-span-1 flex justify-center">{getHSCBuddyOfferedIcon(val)}</span>
                        <span className="flex items-center justify-end gap-2"> 
                            <ArrowLongRightIcon className="w-6" />
                        </span>
                    </div>
                );
            });
        return [headerRow, ...courseRows];
        } else {
            return <div></div>;
        }
    };

    const getAllDisciplineTables = () => {
        if (sortedCourses) {
            return (
                disciplineOrder.map((val, i) => {
                    if (sortedCourses[val]) {
                        return (
                        <div id={val.replace(/\s+/g, '-')} key={i}>
                            <span className="flex gap-2 font-semibold text:lg items-center justify-start pt-12 pb-2">
                                {disciplineIcons[val]}
                                <h1>{val}</h1>
                            </span>
                            {getDisciplineTable(val)}
                        </div>
                        );
                    }
                    return null;
                }).filter(Boolean)
            );
        }
    };
    
	return (
		<div className="w-full h-full overflow-y-scroll flex justify-start text-gray-700 dark:bg-darkbg dark:text-white">
			<div className="w-full pt-8 ml-[5%] mr-[5%]">
                <h1 className="text-2xl font-semibold dark:text-darkdecoration underline-offset-4 decoration-4">
                    <span className="underline decoration-contrast dark:decoration-gray-400">Courses</span>
                </h1>
                <div className="pt-8 break-normal w-5/6 font-light">
                    <span>Discover a wealth of NESA-accredited courses on our platform, designed to support your teaching objectives. Easily access the syllabus information you need to plan effective lessons and assessments.</span>
                </div>
                <hr className="mt-8"></hr>
                <div className="w-2/3 pt-12">
                    <h1 className="font-semibold text-lg">Jump to Learning Area</h1>
                    <div className="py-2 pl-8">
                        {disciplineOrder.map((discipline, index) => (
                            <div key={index} className="flex gap-2 justify-start items-center text-lg mb-2">
                                <ArrowLongRightIcon className="w-6" />
                                <a href={`#${discipline.replace(/\s+/g, '-')}`} className="underline-2 decoration-contrast underline-offset-2 underline">
                                    {discipline}
                                </a>
                            </div>
                        ))}
                    </div>

                </div>
                <hr className="mt-8"></hr>
                <div className="2xl:w-2/3">
                    {getAllDisciplineTables()}
                </div>

                


                <div className="pt-48">
                </div>
            </div>
		</div>
	);
};

export default AllCourses;
