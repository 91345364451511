/* eslint-disable react-hooks/exhaustive-deps */
import { Menu } from "@headlessui/react";
import { ArrowRightIcon, ChevronDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import { getAuth, getRedirectResult, OAuthProvider } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import subjectsContext from "../utilities/subjectsContext";
import ComboboxInput from "../utilities/uicomponents/ComboboxInput";
import { toastOptions, updateStateArray } from "../utilities/utilities";

// TODO:
// Make sure if the user accessed the onboarding page wo signing in (while using SSO) it still creates necessary info (eg: page reload after sso completion)
//

const Onboarding = () => {
	const db = getFirestore();
	const auth = getAuth();

	const [userFirstName, setUserFirstName] = useState("");
	const [userLastName, setUserLastName] = useState("");
	const [userTitle, setUserTitle] = useState("None");

	const [firstNameDisabled, setFirstNameDisabled] = useState<boolean>(false);
	const [lastNameDisabled, setLastNameDisabled] = useState<boolean>(false);
	// const [titleDisabled, setTitleDisabled] = useState<boolean>(false);

	const [canSubmit, setCanSubmit] = useState<boolean>(false);

	const [userCourses, setUserCourses] = useState<string[]>([]);
	const [chosenSubject, setChosenSubject] = useState<string>("");

	const subjects = useContext(subjectsContext);

	useEffect(() => {
		document.title = "HSC Buddy | Onboarding";
		checkIfDetailsMade();
	}, []);

	useEffect(() => {
		if (chosenSubject !== "" && !userCourses.includes(chosenSubject)) setUserCourses((prevVal) => updateStateArray<string>(chosenSubject, prevVal));
	}, [chosenSubject]);

	useEffect(() => {
		setCanSubmit(false);
		if (userFirstName !== "" && userLastName !== "" && userCourses.length > 0) setCanSubmit(true);
	}, [userFirstName, userLastName, userCourses]);

	const checkIfDetailsMade = async () => {
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";
		const docRef = doc(db, "users", userId);
		let accessToken: string = "";

		try {
			const redirectResult = await getRedirectResult(auth);

			if (redirectResult) {
				accessToken = OAuthProvider.credentialFromResult(redirectResult)?.accessToken ?? "";
			}
		} catch (err) {
			console.log(err);
		}

		if (accessToken !== "") {
			fetch("https://graph.microsoft.com/v1.0/me", {
				headers: new Headers({
					Authorization: accessToken,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					if (data["displayName"]) {
						setFirstNameDisabled(true);
						setUserFirstName(data["displayName"].split(' ')[0]);
					}

					if (data["surname"]) {
						setLastNameDisabled(true);
						setUserLastName(data["surname"]);
					}

					setDoc(doc(db, "users", userId), {});
				});
		} else {
			getDoc(docRef).then((docSnap) => {
				if (!docSnap.exists()) {
					setDoc(doc(db, "users", userId), {});
				}
			});
		}
	};

	const handleReset = () => {
		setUserFirstName("");
		setUserLastName("");
		setUserTitle("None");
		setUserCourses([]);
	};

	const handleSubmit = async () => {
		const toastId = toast.loading("Saving...", toastOptions);

		// push the data to firebase
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";

		try {
			await setDoc(doc(db, "users", userId), {
				firstname: userFirstName,
				lastname: userLastName,
				courses: userCourses,
				title: userTitle,
				onboarded: true,
			});
			toast.update(toastId, { render: "Profile saved!", type: "success", isLoading: false, ...toastOptions });
			window.location.reload();
		} catch (err) {
			console.log(err);
			toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
		}
	};

	return (
		<div className="w-screen px-12 md:px-40 pt-24 pb-40 overflow-y-auto dark:bg-darkbg dark:text-white">
			<div>
				<div>
					<h1 className="text-3xl font-semibold">Before we get started...</h1>
					<hr className="w-[400px] mt-2 dark:border-0.5 dark:border-darkgray"></hr>
				</div>
				<div className="pt-4 leading-loose">
					<span>
						👋 Welcome to <span className="underline font-medium decoration-4 underline-offset-2 decoration-contrast">HSC Buddy,</span> your home for teaching resources.
					</span>
					<br />
					<span>Let's set up your account in three quick steps!</span>
				</div>
				<div className="pt-20">
					<span className="font-semibold text-xl pb-2 flex justify-start items-center gap-4">
						<ArrowRightIcon className="w-6" />
						<h1>Confirm your details</h1>
					</span>
					<hr className="w-[400px] pb-4 dark:border-0.5 dark:border-darkgray"></hr>
					<div>
						<div className="flex w-full flex-wrap gap-4">
							<div>
								<p>First Name</p>
								<input
									className="bg-gray-100 px-4 dark:bg-darkgray dark:disabled:bg-darkgray dark:disabled:placeholder:text-gray-400 py-2 rounded-lg border-2 border-gray-400 disabled:bg-slate-200 disabled:text-gray-400"
									placeholder="John"
									value={userFirstName}
									disabled={firstNameDisabled}
									onChange={(e) => {
										setUserFirstName(e.target.value);
									}}
								></input>
							</div>
							<div>
								<p>Last Name</p>
								<input
									className="bg-gray-100 dark:bg-darkgray dark:disabled:bg-darkgray dark:disabled:placeholder:text-gray-400 px-4 py-2 rounded-lg border-2 border-gray-400 disabled:bg-slate-200 disabled:text-gray-400"
									value={userLastName}
									placeholder="Doe"
									disabled={lastNameDisabled}
									onChange={(e) => {
										setUserLastName(e.target.value);
									}}
								></input>
							</div>
						</div>
						<div className="pt-4">
							<p>Title</p>
							<span className="relative flex gap-2">
								<div className="relative">
									<Menu>
										<Menu.Button>
											<input disabled value={userTitle} className={"bg-gray-100 px-4 dark:bg-darkgray dark:disabled:bg-darkgray dark:disabled:placeholder:text-gray-400 dark:text-white py-2 rounded-lg border-2 hover:cursor-pointer hover:bg-gray-200 duration-200 border-gray-400 disabled:text-text"}></input>
											<ChevronDownIcon className={"absolute top-2 right-2 w-8 h-8 "} />
										</Menu.Button>

										<Menu.Items className="absolute right-3 top-0 flex flex-col items-start dark:bg-darkflat bg-white shadow-md rounded-md px-3 py-2 font-light z-10">
											<Menu.Item>
												{({ active }) => (
													<button
														onClick={() => {
															setUserTitle("None");
														}}
														className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
													>
														None
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<button
														onClick={() => {
															setUserTitle("Mr.");
														}}
														className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
													>
														Mr.
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<button
														onClick={() => {
															setUserTitle("Ms.");
														}}
														className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
													>
														Ms.
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<button
														onClick={() => {
															setUserTitle("Mrs.");
														}}
														className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
													>
														Mrs.
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<button
														onClick={() => {
															setUserTitle("Dr.");
														}}
														className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
													>
														Dr.
													</button>
												)}
											</Menu.Item>
										</Menu.Items>
									</Menu>
								</div>
							</span>
						</div>
					</div>
				</div>
				<div className="pt-20">
					<span className="font-semibold text-xl pb-2 flex justify-start items-center gap-4">
						<ArrowRightIcon className="w-6" />
						<h1>Add some courses</h1>
					</span>
					<hr className="w-[400px] pb-2 dark:border-0.5 dark:border-darkgray"></hr>
					<div>
						<div className="pb-4">
							<div className="flex items-center justify-start pt-2 w-[450px]">
								<ComboboxInput 
									placeholder="Chemistry, Biology, ..." 
									setComboboxOutput={setChosenSubject} 
									 
									options={subjects.indexedSubjects} 
								/>
							</div>
						</div>
						<div className="hidden md:block w-2/3">
							<div className="pt-2 flex flex-wrap gap-2 items-center justify-start">
								{userCourses.map((course, index) => {
									return (
										<span key={index} className="flex gap-2 bg-secondary dark:bg-darktan dark:text-text rounded-lg px-4 py-2">
											<span className="break-normal">{course}</span>
											<button onClick={() => setUserCourses((prevVal) => updateStateArray<string>(course, prevVal, true))}>
												<TrashIcon className={"w-6 h-6 text-red-700 "} />
											</button>
										</span>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className="pt-20">
					<span className="font-semibold text-xl pb-2 flex justify-start items-center gap-4">
						<ArrowRightIcon className="w-6" />
						<h1>Check out our guide</h1>
					</span>
					<hr className="w-[400px] pb-2 dark:border-0.5 dark:border-darkgray"></hr>
					<div className="w-3/4">
						<span className="whitespace-normal leading-loose">
							Want to familiarise yourself with our platform? Head on over to our{" "}
							<button
								onClick={() => {
									window.open("https://storage.googleapis.com/hsc-question-finder.appspot.com/HSC%20Buddy%20User%20Guide%20v1.1.pdf", "_blank");
								}}
								className="text-contrast underline underline-offset-2 decoration-2"
							>
								user guide
							</button>{" "}
							to see how to take full advantage of HSC Buddy!
						</span>
					</div>
				</div>
			</div>
			<div className={"flex-wrap space-x-6 pt-20 "}>
				<button onClick={handleReset} className="w-28 md:w-36 px-6 py-3 rounded-lg bg-neutral dark:bg-darktan dark:text-text hover:bg-contrast shadow-md font-semibold text-xl">
					Reset
				</button>
				<button onClick={handleSubmit} disabled={canSubmit ? false : true} className="duration-300 dark:bg-darkgreen dark:text-text w-28 md:w-36 px-6 py-3 rounded-lg bg-flat hover:bg-primary shadow-md font-semibold text-xl disabled:opacity-40">
					Save
				</button>
			</div>
		</div>
	);
};

export default Onboarding;
