/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

interface LoaderProps {
	radius: number;
	stroke: number;
	progress?: number;
	disabled?: boolean;
}

const Loader = (props: LoaderProps) => {
	const { radius, stroke, progress, disabled } = props;
	const [strokeDashoffset, setStrokeDashoffset] = useState<number>(0);
	let interval = useRef<ReturnType<typeof setInterval>>();
	let constantProgress = useRef<number>(1);

	const normalisedRadius = radius - stroke * 2;
	const circumference = normalisedRadius * 2 * Math.PI;

	const constantCycling = () => {
		interval.current = setInterval(() => {
			if (constantProgress.current === 100) {
				constantProgress.current = 1;
				setStrokeDashoffset(circumference - (1 / 100) * circumference);
			} else {
				setStrokeDashoffset(circumference - (constantProgress.current / 100) * circumference);
				constantProgress.current++;
			}
		}, 100);
	};

	useEffect(() => {
		if (progress === undefined && !disabled) {
			console.log("constantCycling");
			constantCycling();
			return () => clearInterval(interval.current);
		} else setStrokeDashoffset(circumference - ((progress ?? 1) / 100) * circumference);
	}, [progress, disabled]);

	return (
		<svg height={radius * 2} width={radius * 2}>
			<circle
				className={"transition-all stroke-[" + stroke + "] " + (disabled ? "stroke-transparent" : "stroke-blue-500")}
				fill="transparent"
				strokeWidth={stroke}
				strokeDasharray={circumference + " " + circumference}
				style={{ strokeDashoffset: strokeDashoffset }}
				r={normalisedRadius}
				cx={radius}
				cy={radius}
			/>
		</svg>
	);
};

export default Loader;
