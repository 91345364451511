/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import ComboboxInput from "../utilities/uicomponents/ComboboxInput";
import { DocumentData, DocumentReference, deleteDoc, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { getDatabase, ref, set } from "firebase/database";
import { v1 as uuid } from "uuid";
import { getAuth } from "firebase/auth";
import subjectsContext from "../utilities/subjectsContext";
import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { toastOptions, updateStateObject } from "../utilities/utilities";
import { Tooltip } from "@material-tailwind/react";
import { ga4react } from "../../Tracking";
import index from "../../AlgoliaInit";
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";

const WorksheetGenerator = (props: { closed: () => void; pathArr: string[]; reload: () => void; updateDarkMode: () => void }) => {
	const [topicList, setTopicList] = useState<string[]>([]);
	const [chosenSubject, setChosenSubject] = useState<string>("");
	const [length, setLength] = useState<{ type: "time" | "questions"; val: number }>({ type: "time", val: 30 });
	const [worksheetName, setWorksheetName] = useState<string>("");
	const [topicInput, setTopicInput] = useState<string>("");
	const [questions, setQuestions] = useState<{ [key: string]: string[] }>({});
	const [numQuestions, setNumQuestions] = useState<number>(0);

	const subjects = useContext(subjectsContext);

	const firestore = getFirestore();
	const db = getDatabase();
	const auth = getAuth();

	useEffect(() => {
		setTopicList([]);
		setTopicInput("");
		setQuestions({});
		setNumQuestions(0);

		if (chosenSubject === "") return;
	}, [chosenSubject]);

	useEffect(() => {
		props.updateDarkMode();
	}, []);

	const searchTopics = async () => {
		if (chosenSubject === "") {
			toast.error("Choose a subject first!", toastOptions);
			return;
		}

		setTopicList((prev) => [...prev, topicInput]);

		const res = await index.search(topicInput, { attributesToRetrieve: ["objectID"], filters: `subject:"${chosenSubject}"` });

		const stateTopic: { [key: string]: string[] } = {};
		stateTopic[topicInput] = res.hits.map((val) => val.objectID);

		console.log(stateTopic[topicInput].length, res.nbHits);

		setQuestions((prev) => updateStateObject(stateTopic, prev));
		setNumQuestions((prev) => (prev += res.nbHits));

		setTopicInput("");
	};

	const handleDeleteTopicChip = (chip: string) => {
		setNumQuestions((prev) => (prev -= questions[chip].length));
		setQuestions((prev) => {
			const newObj = { ...prev };
			delete newObj[chip];
			return newObj;
		});
		setTopicList(() => {
			const newList = [...topicList];
			const index = topicList.indexOf(chip);
			newList.splice(index, 1);
			return newList;
		});
	};

	const generateWorksheet = async () => {
		if (worksheetName === "" || chosenSubject === "" || topicList.length === 0) {
			toast.error("At least one field was empty", { type: "error", isLoading: false, ...toastOptions });
			return;
		} else if (numQuestions === 0) {
			toast.error("Choose 1 or more questions", toastOptions);
			return;
		}

		const toastId = toast.loading(`Generating Worksheet ${worksheetName}...`, { ...toastOptions });

		const userId: string = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";
		const worksheetId = uuid();
		const dbWorksheetRef = ref(db, "worksheetProgress/" + worksheetId);
		const firestoreRef = doc(firestore, "worksheets", "users", userId, worksheetId);

		const url = process.env.NODE_ENV === "production" ? "https://https-worksheetendpoint-main-cwpqhe7pfq-ts.a.run.app" : "http://localhost:5001/hsc-question-finder/australia-southeast1/https-worksheetendpoint-main";

		let maxQuestions = null;
		let maxTime = null;

		if (length.type === "time") {
			maxTime = length.val;
		} else {
			maxQuestions = length.val;
		}

		const { title, firstname, lastname } = (await getDoc(doc(firestore, "users", userId))).data() as any;

		// Creating relating document
		await setDoc(firestoreRef, {
			name: worksheetName,
			course: chosenSubject,
			topics: topicList,
			maxQuestions: maxQuestions,
			maxTime: maxTime,
			publicUrl: null,
			state: "running",
			timestamp: null,
			userId: userId,
			worksheetId: worksheetId,
			filepath: props.pathArr.join("/"),
		}).catch((err) => {
			console.error(err);
		});

		// Creating the percentage complete entry in rt db for progress updates
		await set(dbWorksheetRef, {
			percentComplete: 0,
		}).catch((err) => {
			console.error(err);
		});

		const allQuestions = Object.keys(questions).reduce((acc: string[], val) => {
			return [...questions[val], ...acc];
		}, []);

		const ga = await ga4react;
		const params = {
			worksheetId: worksheetId,
			userId: userId,
			courseName: chosenSubject,
			topics: topicList.join(", "),
			name: `${firstname} ${lastname}`,
			questions: allQuestions,
		};

		const paramsJSON = JSON.stringify(params);

		// Calling cloud function
		fetch(url, {
			method: "POST",
			mode: "cors",
			body: JSON.stringify({
				courseName: chosenSubject,
				questions: allQuestions,
				topics: topicList,
				teacher: title === "None" ? `${firstname} ${lastname}` : `${title} ${firstname} ${lastname}`,
				maxQuestions: maxQuestions ?? 0,
				maxTime: maxTime ?? 0,
				userId: userId,
				worksheetId: worksheetId,
			}),
		})
			.then(async (res) => {
				if (res.ok) {
					toast.update(toastId, { render: "Worksheet has been generated!", type: "success", isLoading: false, ...toastOptions });
					ga.event("worksheet-sucess", "worksheet-sucess", paramsJSON);
				} else {
					generationFail(firestoreRef, ga, toastId, paramsJSON);
				}
			})
			.catch((err) => {
				generationFail(firestoreRef, ga, toastId, paramsJSON);
			});

		props.closed();
	};

	const generationFail = async (worksheetRef: DocumentReference<DocumentData>, ga: GA4ReactResolveInterface, toastId: any, params: any) => {
		toast.update(toastId, { render: "Worksheet failed to generate", type: "error", isLoading: false, ...toastOptions });
		ga.event("worksheet-fail", "worksheet-fail", params);

		await deleteDoc(worksheetRef);

		props.reload();
	};

	return (
		<div className="flex flex-col justify-evenly w-5/6 h-full text-gray-700 dark:text-white ">
			<div className="flex gap-5 items-end flex-wrap lg:flex-nowrap w-full">
				<div className="flex flex-col gap-1 items-start w-full">
					<p>Name your worksheet</p>
					<input
						type="text"
						placeholder="myworksheet.pdf"
						maxLength={10}
						value={worksheetName}
						onChange={(evt) => {
							setWorksheetName(evt.target.value);
						}}
						className="max-h-12 w-full rounded-md px-6 py-3 bg-flatlight dark:bg-darkgray dark:placeholder:text-gray-400 placeholder:text-gray-500 border-none focus:ring-0 focus:bg-flat"
					/>
				</div>
				<div className="flex flex-col gap-1 items-start w-full">
					<p>Pick a course</p>
					<ComboboxInput placeholder="Chemistry, Biology, ..." setComboboxOutput={setChosenSubject} options={subjects.mySubjects} />
				</div>
			</div>
			<div className="flex flex-col gap-1">
				<span className="flex items-center pb-2">
					Set a&nbsp;
					<span className="text-contrast underline underline-offset-2">
						<Tooltip className="w-1/5" content="This will cap the amount of questions your worksheet has. Note we may not have enough questions to fill the time limit completely!">
							<div className="flex justify-start items-center">
								<span>maximum length</span>
								<QuestionMarkCircleIcon className="w-6 ml-1" />
							</div>
						</Tooltip>
					</span>
				</span>
				<div className="flex flex-col gap-3">
					<div className="flex gap-x-6 pb-2 flex-wrap">
						<button
							onClick={() => {
								setLength({ type: "time", val: 30 });
							}}
							className={
								"bg-gray-200 px-8 font-medium py-2 rounded-md border-2 dark:text-text " + (length.type === "time" ? "border-gray-500 dark:border-darktextimpact dark:bg-darktan dark:border-2 font-semibold" : "dark:bg-darktan dark:border-darkbg")
							}
						>
							Time
						</button>
						<button
							onClick={() => {
								setLength({ type: "questions", val: 10 });
							}}
							className={
								"bg-gray-200 px-8 font-medium py-2 rounded-md border-2 dark:text-text " + (length.type === "questions" ? "border-gray-500 dark:border-darktextimpact dark:bg-darktan dark:border-2 font-semibold" : "dark:bg-darktan dark:border-darkbg")
							}
						>
							Questions
						</button>
					</div>
					<div className="flex gap-x-6 gap-y-2 flex-wrap">
						{(length.type === "time" ? [15, 30, 60] : [5, 10, 20]).map((val) => (
							<button
								key={val}
								onClick={() => setLength({ ...length, val: val })}
								className={"bg-gray-200 dark:bg-darktext dark:text-text px-4 py-2 rounded-md border-2 " + (length.val === val ? "border-gray-500 dark:border-darkdecoration dark:bg-darktan dark:opacity-80 dark:border-2 font-semibold" : "")}
							>
								{val}
								{length.type === "time" ? "m" : ""}
							</button>
						))}
						<input
							min="0"
							max="999"
							type="number"
							onChange={(e) => setLength({ ...length, val: parseInt(e.target.value) })}
							placeholder={"Other " + (length.type === "time" ? "(m)" : "")}
							className="bg-gray-200 dark:bg-darktext dark:placeholder:text-gray-400 dark:text-text px-4 w-36 py-2 rounded-md "
						></input>
					</div>
				</div>
			</div>
			<div className="flex flex-col">
				<div className="flex flex-col gap-1 items-start">
					<div className="flex items-center">
						Choose some&nbsp;
						<span className="text-contrast underline underline-offset-2">
							<Tooltip className="w-1/5" content="Topics are searchable keywords that are used to create worksheets. If your topic isn't listed below, please get in contact!">
								<div className="flex justify-start items-center">
									<span>topics</span>
									<QuestionMarkCircleIcon className="w-6 ml-1" />
								</div>
							</Tooltip>
						</span>
					</div>
					<div className="flex flex-col gap-1 w-full">
						<div className="w-full flex gap-5">
							<input
								type="text"
								placeholder="Add some topics..."
								value={topicInput}
								onChange={(event) => setTopicInput(event.target.value)}
								onKeyDown={(event) => {
									if (event.key === "Enter" && topicInput !== "") {
										searchTopics();
									}
								}}
								className="max-h-12 w-full rounded-md px-6 py-3 bg-flatlight dark:bg-darkgray dark:placeholder:text-gray-400 placeholder:text-gray-500 border-none focus:ring-0 focus:bg-flat"
							/>
							<button
								className="px-6 py-2.5 bg-neutral rounded-md dark:bg-darkdecoration dark:text-text disabled:cursor-not-allowed"
								disabled={topicInput === ""}
								onClick={() => {
									searchTopics();
								}}
							>
								Add
							</button>
						</div>
						<span className="text-sm font-light text-gray-500">found {numQuestions} questions</span>
					</div>
				</div>
				<div className="flex flex-wrap pt-4 gap-3 h-15">
					{topicList.map((chip) => (
						<div key={chip} className="bg-neutral rounded-full px-3 py-1 h-fit gap-2 text-sm flex items-center font-normal text-gray-700">
							{chip}
							<button className="" onClick={() => handleDeleteTopicChip(chip)}>
								<XMarkIcon className="w-6 h-6" />
							</button>
						</div>
					))}
				</div>
			</div>
			<div className="flex justify-center">
				<button
					className="px-6 py-3 rounded-lg bg-secondary hover:bg-primary shadow-sm font-semibold text-xl dark:bg-darkdecoration dark:text-text"
					onClick={() => {
						generateWorksheet();
					}}
				>
					Generate
				</button>
			</div>
		</div>
	);
};

export default WorksheetGenerator;
