/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import subjectsContext from "./utilities/subjectsContext";
import ComboboxInput from "./utilities/uicomponents/ComboboxInput";
import { paper, toastOptions } from "./utilities/utilities";
import { ga4react } from "../Tracking";
import { useLocation } from "react-router-dom";

const Papers = () => {
	const [currentSubject, setCurrentSubject] = useState<string>("");
	const [currentYear, setCurrentYear] = useState<string>("");

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const subjects = useContext(subjectsContext);

	useEffect(() => {
		const search = searchParams.get("p");
		setCurrentSubject(search !== null ? search : "");
	}, []);

	useEffect(() => {
		document.title = "HSC Buddy | Papers";
	}, [])
		

	const submit = async (type: string) => {
		if (currentSubject === "") {
			toast.error("You must choose a valid subject!", toastOptions);
			return;
		}

		let getPaper: paper = { Paper: "", MG: "" };
		if (currentYear === "") {
			const keys = Object.keys(subjects.subjects[currentSubject]);
			const randomYear = parseInt(keys[Math.floor(Math.random() * keys.length)]);
			getPaper = subjects.subjects[currentSubject][randomYear] ?? undefined;
		} else {
			getPaper = subjects.subjects[currentSubject][parseInt(currentYear)] ?? undefined;
		}

		if (getPaper === undefined) {
			toast.error("You must choose a valid year!", toastOptions);
		} else if (type === "questions") {
			window.open(getPaper.Paper, "_blank");

			const ga = await ga4react;
			const params = {
				course: currentSubject,
				year: currentYear,
				type: "questions",
			};
			const paramsJSON = JSON.stringify(params);
			ga.event("paper-opened", "paper-opened", paramsJSON);
		} else if (type === "solutions") {
			window.open(getPaper.MG, "_blank");

			const ga = await ga4react;
			const params = {
				course: currentSubject,
				year: currentYear,
				type: "solutions",
			};
			const paramsJSON = JSON.stringify(params);
			ga.event("paper-opened", "paper-opened", paramsJSON);
		}
	};

	return (
		<div className="w-full h-full flex justify-center items-center text-gray-700 dark:bg-darkbg dark:text-white">
			<div className="flex flex-col w-5/6 max-w-6xl gap-20">
				<div className="">
					<span className="font-semibold text-left dark:text-darktext">Paper Search</span>
					<div className="w-full flex items-center justify-between">
						<p className="w-1/2 mt-1 text-sm lg:text-base font-normal text-gray-500 dark:text-white">Quickly access any past HSC papers' questions or marking guidelines, or choose a subject and get a random year's paper.</p>
					</div>
				</div>
				<div className="flex gap-5">
					<div className="flex flex-col gap-1 items-start font-medium dark:text-darktext">
						<p>Pick a course</p>
						<ComboboxInput 
							placeholder="Subject" 
							setComboboxOutput={setCurrentSubject} 
							options={subjects.mySubjects} 
							comboboxInput={currentSubject}
						/>
					</div>
					<div className="flex flex-col gap-1 items-start font-medium dark:text-darktext">
						<p>Pick a year (optional)</p>
						<ComboboxInput 
							placeholder="Year" 
							setComboboxOutput={setCurrentYear} 
							options={currentSubject !== "" ? Object.keys(subjects.subjects[currentSubject]) : []} 
						/>
					</div>
				</div>
				<div className="flex gap-3">
					<button
						className="px-6 py-3 rounded-lg bg-secondary hover:bg-primary shadow-sm font-medium dark:bg-darkdecoration dark:text-text duration-200 hover:opacity-80"
						onClick={() => {
							submit("questions");
						}}
					>
						Questions
					</button>
					<button
						className="px-6 py-3 rounded-lg bg-secondary hover:bg-primary shadow-sm font-medium dark:bg-darktan dark:text-text duration-200 hover:opacity-80"
						onClick={() => {
							submit("solutions");
						}}
					>
						Solutions
					</button>
				</div>
			</div>
		</div>
	);
};

export default Papers;
