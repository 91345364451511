/* eslint-disable react-hooks/exhaustive-deps */
import {
	MagnifyingGlassIcon,
	EllipsisVerticalIcon,
	PlusIcon,
	ArrowDownTrayIcon,
	TrashIcon,
	ChevronDownIcon,
	ArrowUturnLeftIcon,
	LinkIcon,
	PaperAirplaneIcon,
	ArrowLongRightIcon,
	FolderIcon,
	FolderPlusIcon,
	DocumentPlusIcon,
	Square2StackIcon,
	ArrowRightIcon,
	ArrowUturnRightIcon,
} from "@heroicons/react/24/outline";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Dialog, Menu, Popover } from "@headlessui/react";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, setDoc, Timestamp, Unsubscribe as fsUnsubscribe, updateDoc } from "firebase/firestore";

import { v1 as uuid } from "uuid";
import { getAuth } from "firebase/auth";
import { handleBlur, toastOptions, updateStateArray, worksheetObj } from "../utilities/utilities";
import { getDatabase, off, onValue, ref as rtRef, Unsubscribe as rtUnsubscribe } from "firebase/database";
import { deleteObject, getBlob, getStorage, ref as storageRef } from "firebase/storage";
import { saveAs } from "file-saver";
import { Id, toast } from "react-toastify";
import WorksheetGenerator from "./WorksheetGenerator";
import Table from "../utilities/uicomponents/Table";
import JSZip from "jszip";
import { useNavigate, useSearchParams } from "react-router-dom";
import WorksheetRow from "./worksheetRowData";
import { ga4react } from "../../Tracking";
import useDarkMode from "../utilities/darkMode";
import useWindowDimensions from "../utilities/windowDimensions";

//TODO:
// Make checkbox do stuff

const AllWorksheets = () => {
	interface CourseDescription {
		code: string;
		dicipline: string;
	}
	// Raw data, list used for sorting and filtering results
	const [worksheetData, setWorksheetData] = useState<{ [key: string]: worksheetObj }>({});
	const [worksheetIds, setWorksheetIds] = useState<string[]>([]);
	const [filteredIds, setFilteredIds] = useState<string[]>([]);
	// const [dbWorksheets, setDbWorksheets] = useState<{ [key: string]: worksheetObj }>({});

	// Listener for progress as well as timeouts in case progress takes > 1 min
	const timeouts = useRef<NodeJS.Timeout[]>([]);
	const listeners = useRef<Array<[fsUnsubscribe, rtUnsubscribe]>>([]);

	const [folderNamingOpen, setFolderNamingOpen] = useState<boolean>(false);
	const [newFolderName, setNewFolderName] = useState<string>("");
	const [isRenderingNewFolder, setIsRenderingNewFolder] = useState<boolean>(false);

	const [searchInput, setSearchInput] = useState<string>("");
	const [sorting, setSorting] = useState<string>("Time");

	const [nickNames, setNickNames] = useState<{ [key: string]: CourseDescription }>({});

	const [generatorOpen, setGeneratorOpen] = useState<boolean>(false);
	const [displayNum, setDisplayNum] = useState<[number, number]>([0, 0]);
	const [totalResults, setTotalResults] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);

	// Keeping track of selected worksheets
	const [selectedWorksheets, setSelectedWorksheets] = useState<string[]>([]);
	const [sharePromptOpen, setSharePromptOpen] = useState<boolean>(false);

	// Firebase Details
	const firestoreDB = getFirestore();
	const realtimeDB = getDatabase();
	const storage = getStorage();
	const auth = getAuth();
	const [searchParams] = useSearchParams();

	const navigate = useNavigate();
	const [pathArr, setPathArr] = useState<string[]>([]);

	const [userName, setUserName] = useState<string>("");

	const [checkboxesVisible, setCheckboxesVisible] = useState<boolean>(false);

	// Headless ui darkmode
	const shareRef = useRef<HTMLDivElement>(null);
	const generatorRef = useRef<HTMLDivElement>(null);

	const updateDarkMode = useDarkMode(shareRef, generatorRef);
	const { width } = useWindowDimensions();

	useEffect(() => {
		setPathArr(searchParams.get("p")?.split("/") ?? []);
		if (searchParams.get("new") === "true") {
			setGeneratorOpen(true);
		}
	}, [searchParams]);

	useEffect(() => {
		document.title = "HSC Buddy | Worksheets";
	}, [])

	// On first load, fetch data from firestore
	// When content no longer displayed kill timeouts and listeners
	useEffect(() => {
		fetchWorksheetData();
		fetchUserData();

		return () => {
			console.log("Clearing all timeouts and listeners");
			timeouts.current.forEach((timeout) => {
				clearTimeout(timeout);
			});
			listeners.current.forEach(([fsListener, rtListener]) => {
				fsListener();
				rtListener();
			});

			off(rtRef(realtimeDB, "worksheetProgress"));
		};
	}, []);

	const sort = (first: worksheetObj, second: worksheetObj) => {
		if (sorting === "Time") {
			if (!first.timestamp && !second.timestamp) return 0;
			else if (!first.timestamp) return -1;
			else if (!second.timestamp) return 1;
			else return second.timestamp.toDate().getTime() - first.timestamp.toDate().getTime();
		} else if (sorting === "Course") {
			const courseA = first.name.toLowerCase();
			const courseB = first.name.toLowerCase();

			if (courseA < courseB) return -1;
			else if (courseA > courseB) return 1;
			else return 0;
		} else {
			const nameA = first.name.toLowerCase();
			const nameB = second.name.toLowerCase();

			if (nameA < nameB) return -1;
			else if (nameA > nameB) return 1;
			else return 0;
		}
	};

	useEffect(() => {
		// Sorting the results
		setFilteredIds(() => {
			let newIds = [...worksheetIds];

			// Filtering for search functionality
			newIds = newIds.filter((val, index) => {
				let name = worksheetData[val].name;
				let course = "";
				// let dicipline = "";
				let nick = "";

				if (worksheetData[val].course !== "") {
					const courseDesc = nickNames[worksheetData[val].course];
					course = worksheetData[val].course.toLowerCase();
					// dicipline = courseDesc.dicipline.toLowerCase();
          nick = courseDesc.code.toLowerCase();
				}

				if (name.includes(searchInput.toLowerCase()) || course.includes(searchInput.toLowerCase()) || nick.includes(searchInput.toLowerCase())) return true;
				return false;
			});

			// Filtering file path
			newIds = newIds.filter((val, index) => {
				const itemFp = worksheetData[val].filepath;
				const curFp = searchParams.get("p") ?? "";

				if (itemFp === "" && curFp === "") return true;
				else if (itemFp === curFp && worksheetData[val].course !== "") return true;
				else if (worksheetData[val].course !== "") return false;

				// Checking for a nested folder
				let folderPath = itemFp.split("/");
				const currentPath = curFp.split("/");
				folderPath = folderPath.slice(0, folderPath.length - 1);

				return folderEqualsCheck(currentPath, folderPath);
			});

			newIds = newIds.sort((a, b) => {
				const first: worksheetObj = worksheetData[a];
				const second: worksheetObj = worksheetData[b];

				// Check if either is a folder and sort to the top
				if (first.course === "" && second.course === "") {
					return sort(first, second);
				} else if (!first.course) {
					return -1;
				} else if (!second.course) {
					return 1;
				} else {
					return sort(first, second);
				}
			});

			setTotalResults(newIds.length);

			return newIds;
		});
	}, [worksheetIds, sorting, searchInput, pathArr]);

	const folderEqualsCheck = (a: string[], b: string[]) => a.length === b.length && a.every((v, i) => v === b[i]);

	const updateWorksheetData = (id: string, obj?: any, del?: boolean) => {
		setWorksheetData((prevValue): { [key: string]: worksheetObj } => {
			const newValue = { ...prevValue };
			if (del) delete newValue[id];
			else newValue[id] = { ...newValue[id], ...obj };
			return newValue;
		});
	};

	const fetchUserData = async () => {
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";
		const docRef = doc(firestoreDB, "users", userId);
		const document = await getDoc(docRef);

		if (!document.exists()) return;

		const data = document.data();

		setUserName(data["firstname"]);
	};

	const fetchWorksheetData = async () => {
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";

		const query = collection(firestoreDB, "worksheets", "users", userId);
		const querySnapshot = await getDocs(query);

		const nickSnapshot = await getDoc(doc(firestoreDB, "courseDescriptions", "codes"));

		if (nickSnapshot.exists()) setNickNames(nickSnapshot.data());

		let worksheetObjs: { [key: string]: worksheetObj } = {};
		let worksheetId: string[] = [];
		let folderPaths: string[] = [];
		let folderNames: string[] = [];

		querySnapshot.forEach((doc) => {
			worksheetObjs[doc.id] = doc.data() as worksheetObj;
		});

		querySnapshot.forEach((doc) => {
			const rowData: worksheetObj = doc.data() as worksheetObj;
			const fp = rowData.filepath.split("/");

			if (fp.length > 1) {
				fp.forEach((val, i) => {
					let folderArr = fp.slice(0, i + 1);
					let folderPath: string = "";

					if (folderArr.length > 1) folderPath = folderArr.join("/");
					// if (folderArr.length > 1 && rowData.course === "") {
					// 	folderPath = folderArr.slice(0, folderArr.length).join("/");
					// }

					if (!folderPaths.includes(folderPath) && folderPath) {
						folderPaths.push(folderPath);
						const newId = uuid();

						updateWorksheetData(newId, { course: "", maxQuestions: null, maxTime: null, name: val, publicUrl: null, state: "done", timestamp: null, topics: [], userId: rowData.userId, worksheetId: null, filepath: folderPath });
						worksheetId.push(newId);
					} else if (!folderPath && !folderNames.includes(val)) {
						folderNames.push(val);
						const newId = uuid();

						updateWorksheetData(newId, { course: "", maxQuestions: null, maxTime: null, name: val, publicUrl: null, state: "done", timestamp: null, topics: [], userId: rowData.userId, worksheetId: null, filepath: folderPath });
						worksheetId.push(newId);
					}
				});
			}

			if (rowData.course !== "") {
				updateWorksheetData(doc.id, rowData);
				worksheetId.push(doc.id);
			} else if (!folderNames.includes(rowData.name) && fp.length === 1) {
				folderNames.push(rowData.name);
				updateWorksheetData(doc.id, { ...rowData, filepath: "" });
				worksheetId.push(doc.id);
			}

			if (doc.data()["state"] === "running") getWorksheetProgress(doc.id);
		});

		setTotalResults(worksheetId.length);
		// setDbWorksheets(worksheetObjs);
		setWorksheetIds(worksheetId);
		setLoading(false);
	};

	const getWorksheetProgress = (id: string) => {
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";
		const worksheetProgressRef = rtRef(realtimeDB, "worksheetProgress/" + id);
		const progressListener = onValue(worksheetProgressRef, (snapshot) => {
			updateWorksheetData(id, { progress: snapshot.val()["percentComplete"] });
		});
		const stateListener = onSnapshot(doc(firestoreDB, "worksheets", "users", userId, id), (doc) => {
			const data: worksheetObj = (doc.data() as worksheetObj) ?? {};
			const id: string = doc.id ?? "";

			updateWorksheetData(id, data);

			if (data["state"] === "done" || data["state"] === "error") {
				stateListener();
				progressListener();
				console.log("cancelled progress listener due to progress being complete");
			}
		});

		listeners.current.push([stateListener, progressListener]);

		// If progress doesnt complete in 1 min, cancel listeners
		timeouts.current.push(
			setTimeout(() => {
				stateListener();
				progressListener();
				console.log("cancelled progress listener on value: ", id);
			}, 60000)
		);
	};

	const downloadMultipleWorksheets = async (ids: string[]) => {
		const toastId = toast.loading("Downloading", toastOptions);
		let zip = new JSZip();
		let uploadedFiles: { [key: string]: number } = {};

		try {
			await Promise.all(
				ids.map(async (id) => {
					const downloadBlob = await getBlob(storageRef(storage, "worksheet/" + id + ".pdf"));
					const name = worksheetData[id].name;

					if (Object.keys(uploadedFiles).includes(name)) {
						uploadedFiles[name]++;
						zip = zip.file(name + "(" + uploadedFiles[name] + ").pdf", downloadBlob);
					} else {
						zip = zip.file(name + ".pdf", downloadBlob);
						uploadedFiles[name] = 1;
					}
				})
			);

			let zippedBlob = await zip.generateAsync({ type: "blob" });
			saveAs(zippedBlob, "worksheets.zip");
			toast.update(toastId, { render: "File Downloaded!", type: "success", isLoading: false, ...toastOptions });
		} catch (err) {
			console.log(err);
			toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
		}
	};

	const download = async (id: string) => {
		const toastId = toast.loading("Downloading", toastOptions);

		try {
			const downloadBlob = await getBlob(storageRef(storage, "worksheet/" + id + ".pdf"));
			saveAs(downloadBlob, worksheetData[id].name);
			toast.update(toastId, { render: "File Downloaded!", type: "success", isLoading: false, ...toastOptions });
		} catch (err) {
			console.log(err);
			toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
		}
	};

	const share = async (ids: string[], emails: string[]) => {
		const toastId = toast.loading(ids.length === 1 ? `Sharing worksheet ${worksheetData[ids[0]]["name"]}...` : `Sharing worksheets...`, { ...toastOptions });
		const url = process.env.NODE_ENV === "production" ? "https://https-emailendpoint-cwpqhe7pfq-ts.a.run.app" : "http://localhost:5001/hsc-question-finder/australia-southeast1/https-emailendpoint";
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";
		const userDocRef = doc(firestoreDB, "users", userId);
		const userDocument = await getDoc(userDocRef);
		let fromName: string;
		if (userDocument.exists()) fromName = userDocument.data().firstname + " " + userDocument.data().lastname;
		else fromName = "NULL";

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: JSON.stringify({
				toEmails: emails,
				fromName: fromName,
				worksheetIds: ids,
				fromUserId: userId,
			}),
		})
			.then(async (res) => {
				if (res.ok) {
					toast.update(toastId, { render: `Worksheet${ids.length === 1 ? " " + worksheetData[ids[0]]["name"] + " has " : " have"} been shared!`, type: "success", isLoading: false, ...toastOptions });
					const ga = await ga4react;
					const params = {
						fromUserId: userId,
						fromName: fromName,
						worksheetIds: ids.join("; "),
						toEmails: emails.join("; "),
					};
					const paramsJSON = JSON.stringify(params);
					ga.event("worksheet-shared", "worksheet-shared", paramsJSON);
				} else {
					toast.error("Worksheet failed to share, " + res.status, { ...toastOptions });
					const ga = await ga4react;
					const params = {
						fromUserId: userId,
						fromName: fromName,
						worksheetIds: ids.join("; "),
						toEmails: emails.join("; "),
					};
					const paramsJSON = JSON.stringify(params);
					ga.event("worksheet-share-failed", "worksheet-share-failed", paramsJSON);
				}
			})
			.catch((err) => {
				toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
				console.error(err);
			});
	};

	const renameUpload = async (input: [string, string], toastId: Id) => {
		const newName = input[1];
		const worksheetId = input[0];

		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";

		try {
			await updateDoc(doc(firestoreDB, "worksheets", "users", userId, worksheetId), { name: newName });

			updateWorksheetData(worksheetId, { name: newName });
			toast.update(toastId, { render: "Name updated!", type: "success", isLoading: false, ...toastOptions });
			const ga = await ga4react;
			const params = {
				userId: userId,
				worksheetId: worksheetId,
				newName: newName,
			};
			const paramsJSON = JSON.stringify(params);
			ga.event("worksheet-renamed", "worksheet-renamed", paramsJSON);
		} catch (err) {
			console.log(err);
			toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
		}
	};

	const deleteWorksheet = async (id: string) => {
		const toastId = toast.loading("Deleting worksheet", toastOptions);
		const userId = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";

		try {
			await deleteDoc(doc(firestoreDB, "worksheets", "users", userId, id));
			await deleteObject(storageRef(storage, "worksheet/" + id + ".pdf"));
			toast.update(toastId, { render: "Worksheet deleted!", type: "success", autoClose: 1000, isLoading: false, ...toastOptions });
			const ga = await ga4react;
			ga.event("worksheet-deleted", "worksheet-deleted", id);
		} catch (err) {
			toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
		}

		setTotalResults((prevTotalResults) => prevTotalResults--);
		setWorksheetIds((worksheetIds) => updateStateArray<string>(id, worksheetIds, true));

		updateWorksheetData(id, {}, true);
	};

	const deleteFolder = async (id: string) => {
		// let folder = worksheetData[id].filepath;
		// const deleteArr: string[] = [id];
		// if (folder === "") {
		// 	folder = worksheetData[id].name + "/";
		// }
		// worksheetIds.forEach((val) => {
		// 	const data = worksheetData[val];
		// 	if (data.filepath.startsWith(folder)) {
		// 		// console.log(data.worksheetId, data.filepath, folder, data.name);
		// 	}
		// });
		// console.log(deleteArr);
		// deleteMultipleWorksheets(deleteArr);
	};

	const deleteMultipleWorksheets = async (idArray: string[], checkbox = true) => {
		idArray.forEach(async (id) => {
			await deleteWorksheet(id);
			if (checkbox) {
				setSelectedWorksheets((selectedWorksheets) => updateStateArray<string>(id, selectedWorksheets, true));
			}
		});
	};

	const createNewFolder = async (newFolderName: string, filepath: string[]) => {
		if (newFolderName === "") {
			toast.error("Folder names must not be blank", toastOptions);
			setFolderNamingOpen(false);
			setNewFolderName("");
			return;
		} else if (pathArr.length > 4) {
			toast.error("Maximum folder depth hit!", toastOptions);
			setFolderNamingOpen(false);
			setNewFolderName("");
			return;
		}

		// create an empty worksheet object in users db with 'folder=true' with the relative path
		const toastId = toast.loading(`Creating folder ${newFolderName}...`, { ...toastOptions });

		const userId: string = auth.currentUser ? auth.currentUser.uid : "USERNOTSIGNEDIN";
		const worksheetId = uuid();
		const firestoreRef = doc(firestoreDB, "worksheets", "users", userId, worksheetId);

		const fp = filepath.length === 0 ? newFolderName : filepath.join("/") + "/" + newFolderName;
		const timestamp = Timestamp.fromDate(new Date(Date.now()));
		let stop = false;

		Object.keys(worksheetData).forEach((val) => {
			if (worksheetData[val].course === "" && worksheetData[val].filepath === fp) {
				setFolderNamingOpen(false);
				setNewFolderName("");
				stop = true;
			}
		});

		if (stop) {
			setTimeout(() => {
				toast.update(toastId, { render: "Folder already exists", type: "error", isLoading: false, ...toastOptions });
			}, 100);

			return;
		}

		// Creating relating document
		await setDoc(firestoreRef, {
			name: newFolderName,
			course: "",
			topics: "",
			maxQuestions: "",
			maxTime: "",
			publicUrl: null,
			state: "done",
			timestamp: timestamp,
			userId: userId,
			worksheetId: worksheetId,
			filepath: fp,
		})
			.catch(async (err) => {
				toast.update(toastId, { render: err as string, type: "error", isLoading: false, ...toastOptions });
				console.error(err);
				const ga = await ga4react;
				const params = {
					userId: userId,
					folderName: newFolderName,
					filePath: fp,
					error: err,
				};

				const paramsJSON = JSON.stringify(params);
				ga.event("folder-failed", "folder-creation-failed", paramsJSON);
			})
			.then(async () => {
				toast.update(toastId, { render: "Folder created", type: "success", isLoading: false, ...toastOptions });
				const ga = await ga4react;
				const params = {
					userId: userId,
					folderName: newFolderName,
					filePath: fp,
				};

				const paramsJSON = JSON.stringify(params);
				ga.event("folder-created", "folder-created", paramsJSON);
			});

		setFolderNamingOpen(false);
		fetchWorksheetData();
		setNewFolderName("");
	};

	const renderNewFolder = () => {
		if (folderNamingOpen) {
			return isRenderingNewFolder ? (
				<Popover className="relative w-fit h-fit">
					<Popover.Panel static className="absolute top-12 flex flex-col gap-2 bg-secondary dark:bg-darkbg shadow-md rounded-md px-5 py-2 font-light z-10" onBlur={(e: any) => handleBlur(e, "newfolder", undefined, setFolderNamingOpen)}>
						<span className="w-full text-sm pt-1">Enter a name for your folder</span>
						<div className="flex gap-2 w-full pb-2">
							<input
								autoFocus
								type="text"
								value={newFolderName}
								onChange={(evt) => {
									setNewFolderName(evt.target.value);
								}}
								className="w-44 max-h-12 rounded-md px-6 py-3 bg-primary dark:bg-darkgray dark:placeholder:text-gray-400 placeholder:text-gray-500 duration-100 border-none focus:ring-0 focus:bg-flat"
								maxLength={10}
								placeholder="New Name"
							/>
							<button
								className="bg-primary px-3 py-1 rounded-md hover:bg-contrast dark:bg-darkdecoration dark:text-text dark:hover:opacity-80 duration-100 flex items-center gap-2"
								onClick={() => {
									createNewFolder(newFolderName, pathArr);
								}}
							>
								Add
								<ArrowRightIcon className="w-4" />
							</button>
						</div>
					</Popover.Panel>
				</Popover>
			) : (
				<div></div>
			);
		} else {
			return <div></div>;
		}
	};

	const checkValidEmail = (email: string) => {
		// eslint-disable-next-line no-useless-escape
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(email)) return true;
		return false;
	};

	const [addingEmail, setAddingEmail] = useState<string>("");
	const [sharedEmails, setSharedEmails] = useState<string[]>([]);
	const [inputShake, setInputShake] = useState<boolean>(false);

	const submitShare = () => {
		if (sharedEmails.length > 0 && sharedEmails.length < 11) {
			share(selectedWorksheets, sharedEmails);
			setSharedEmails([]);
			setAddingEmail("");
			setInputShake(false);
			setSharePromptOpen(false);
			if (width <= 1024) setSelectedWorksheets([]);
		} else if (sharedEmails.length === 0 && checkValidEmail(addingEmail)) {
			share(selectedWorksheets, [addingEmail]);
			setSharedEmails([]);
			setAddingEmail("");
			setInputShake(false);
			setSharePromptOpen(false);
			if (width <= 1024) setSelectedWorksheets([]);
		}
	};

	const getSharedWorksheetsDescription = () => (
		<span className="flex gap-2 justify-start items-center">
			<ArrowLongRightIcon className="w-6" />
			<div>
				Sharing&nbsp;
				{selectedWorksheets.map((worksheet, id) => {
					const worksheetSpan = <span className="underline-4 underline-offset-2 decoration-primary underline italic">{worksheetData[worksheet]["name"]}</span>;

					if (id === selectedWorksheets.length - 1) {
						return <span key={id}>{worksheetSpan}</span>;
					} else if (id === selectedWorksheets.length - 2) {
						return <span key={id}>{worksheetSpan} and </span>;
					} else {
						return <span key={id}>{worksheetSpan}, </span>;
					}
				})}
			</div>
		</span>
	);

	const formatTimestamp = (timestamp: Timestamp) => {
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			timeZone: "Australia/Sydney",
			hour12: true,
		};
		const date = timestamp.toDate();
		return new Intl.DateTimeFormat("en-AU", options).format(date);
	};

	const sharePrompt = () => {
		// results in flickering, not sure how to fix
		setTimeout(() => {
			updateDarkMode();
		}, 0);
		return (
			<Dialog
				ref={shareRef}
				open={sharePromptOpen}
				onClose={() => {
					setSharePromptOpen(false);
					setSharedEmails([]);
					setAddingEmail("");
					setInputShake(false);
					if (width <= 1024) setSelectedWorksheets([]);
					//getSharedWorksheetsDescription();
				}}
				className="absolute inset-0 w-screen h-screen flex items-center justify-center bg-transparent"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true" />
				<Dialog.Panel className="h-3/5 w-3/4 lg:w-2/3 xl:w-1/2 bg-white dark:bg-darkbg z-10 rounded-lg shadow-2xl dark:bg-darbg font-poppins p-10 flex items-center justify-between flex-col">
					<Dialog.Title className="flex flex-col gap-2 self-start justify-start">
						<span className="font-semibold text-2xl dark:text-darkdecoration">Share Worksheets</span>
						<div>
							<span className="pt-2 dark:text-white">Share your worksheets with colleagues or students.</span>
							<span className="pb-1 pt-2 text-sm font-light dark:text-white">
								{/* Todo: replace this with a function that returns a div element formatted prettily given names */}
								{/* Sharing <span className="underline-4 underline-offset-2 decoration-primary underline">algorithms</span>, <span>topicTest1</span> and <span>topicTest2</span> */}
								{getSharedWorksheetsDescription()}
							</span>
						</div>
					</Dialog.Title>
					<Dialog.Description as="div" className="flex items-center justify-center w-full">
						<div className="flex flex-col justify-center items-center w-full dark:bg-darkbg">
							<div className={"relative flex w-2/3 max-w-[450px] " + (inputShake ? "animate-inputShake" : null)}>
								<input
									className={
										"mt-1 w-full h-12 duration-100 bg-flatlight dark:text-white dark:bg-darkflat dark:placeholder:text-gray-400 focus:bg-flat px-4 py-2 rounded-lg !outline-none border-transparent focus:ring-0 disabled:bg-slate-200 disabled:text-gray-400 " +
										(inputShake ? "shadow-md text-red-600 shadow-red-500" : null)
									}
									placeholder="me@company.com"
									value={addingEmail}
									onClick={() => {
										if (inputShake) setAddingEmail("");
										setInputShake(false);
									}}
									onChange={(e) => {
										setAddingEmail(e.target.value);
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											if (checkValidEmail(addingEmail)) {
												setSharedEmails((prevVal) => updateStateArray<string>(addingEmail, prevVal));
												setAddingEmail("");
												setInputShake(false);
											} else {
												setInputShake(true);
											}
										}
									}}
								/>
								<button
									onClick={() => {
										if (checkValidEmail(addingEmail)) {
											setSharedEmails((prevVal) => updateStateArray<string>(addingEmail, prevVal));
											setAddingEmail("");
										} else {
											setInputShake(true);
										}
									}}
								>
									<ArrowUturnRightIcon className="w-7 dark:text-white dark:hover:text-darkdecoration flex-none absolute right-6 top-3.5 duration-200 hover:text-black text-gray-600" />
								</button>
							</div>
							<div className="w-2/3 pt-6 gap-4 flex-wrap flex justify-start">
								{sharedEmails.map((email, index) => {
									return (
										<span key={index} className="flex gap-2 bg-secondary rounded-lg px-2 py-1.5 w-fit">
											<span className="break-normal text-sm">{email}</span>
											<button onClick={() => setSharedEmails((prevVal) => updateStateArray<string>(email, prevVal, true))}>
												<TrashIcon className={"w-5 h-5 text-red-700 "} />
											</button>
										</span>
									);
								})}
							</div>
							<div className={"flex items-center justify-center flex-wrap w-2/3 gap-6 pt-12"}>
								<button
									onClick={() => {
										setSharePromptOpen(false);
										setSharedEmails([]);
										setAddingEmail("");
										setInputShake(false);
									}}
									className="duration-300 min-w-fit flex-shrink-0 w-2/5 px-6 py-3 rounded-lg dark:bg-darktan bg-neutral hover:bg-contrast shadow-md font-semibold text-xl"
								>
									<span className="flex items-center justify-center h-8">
										<span>Cancel</span>
									</span>
								</button>
								<button
									disabled={sharedEmails.length === 0}
									onClick={() => submitShare()}
									className="duration-300 min-w-fit flex-shrink-0 w-2/5 px-6 py-3 rounded-lg bg-flat dark:bg-darkgreen hover:bg-primary shadow-md font-semibold text-xl disabled:opacity-40"
								>
									<span className="flex items-center justify-center gap-4">
										<span>Send</span> <PaperAirplaneIcon className="w-8 h-8 flex-none" />
									</span>
								</button>
							</div>
						</div>
					</Dialog.Description>
					<div />
				</Dialog.Panel>
			</Dialog>
		);
	};

	const getTableData: () => ReactNode[] | "searchEmpty" | "noResult" = () => {
		if (worksheetIds.length === 0) return "noResult";

		const [minResults, maxResults] = displayNum;
		let index = 0;

		const fileOutput = filteredIds
			.filter((_, index) => {
				if (index + 1 < minResults || index + 1 > maxResults) return false;
				return true;
			})
			.map((data, i) => {
				const rowData: worksheetObj = worksheetData[data];
				if (!rowData) return <tr key={i} />;

				let nick: string = "null";
				if (nickNames) {
					if (rowData.course === "") {
						nick = ""
					}
					else {
						const courseDescription = nickNames[rowData.course];
						nick = courseDescription.code;
					}
					
				}
				else {
					console.log("error finding nicknames", nickNames)
				}

				let time: string = "Being Created";

				if (rowData.timestamp) {
					time = formatTimestamp(rowData.timestamp);
				}

				let loading = rowData.state !== "done";
				index++;

				if (rowData.course === "") {
					return (
						<WorksheetRow
							rowKey={index}
							bgColour={index % 2 !== 0 ? "bg-white dark:bg-darktable1" : "bg-transparent"}
							name={rowData.name}
							subjectName={"---"}
							time={"---"}
							loading={loading}
							id={data}
							checkboxesVisible={checkboxesVisible}
							uploadRename={() => {}}
							folder
							openFolder={openFolder}
							download={() => {}}
							deleteWorksheet={deleteFolder}
						/>
					);
				} else {
					return (
						<WorksheetRow
							rowKey={index}
							bgColour={index % 2 !== 0 ? "bg-white dark:bg-darktable1" : "bg-transparent"}
							name={rowData.name}
							subjectName={nick}
							time={time}
							loading={loading}
							id={data}
							progress={rowData.progress}
							selected={{ val: selectedWorksheets, update: setSelectedWorksheets }}
							setSharePromptOpen={setSharePromptOpen}
							checkboxesVisible={checkboxesVisible}
							download={download}
							deleteWorksheet={deleteWorksheet}
							uploadRename={renameUpload}
						/>
					);
				}
			});
		if (fileOutput.length === 0) return "searchEmpty";
		else return fileOutput;
	};

	const selectedHeader: () => JSX.Element[] = () => {
		const header = ["", "Name", "Course", "Time", selectedWorksheets.length + ""];
		return header.map((val, i) => (
			<th
				key={i}
				scope="col"
				className={
					"py-3 dark:bg-darkgray w-full h-full " +
					(i === header.length - 1 ? "rounded-tr-md justify-start relative " : "") +
					(i === 1 ? "md:rounded-tl-md lg:rounded-none " : "") +
					(i === 0 ? "pl-6 justify-start rounded-tl-md md:hidden lg:inline-block " : "") +
					(i > 0 && i < header.length - 1 ? "px-6 text-center " : "")
				}
			>
				{val && i === header.length - 1 ? (
					<Menu>
						<Menu.Button className="flex items-center justify-end gap-1 w-full">
							{val}
							<EllipsisVerticalIcon className="w-8 h-8" />
						</Menu.Button>
						<Menu.Items className="absolute top-full flex flex-col items-start bg-white dark:bg-darkbg shadow-md rounded-md px-3 py-2 font-light z-10">
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={() => {
											downloadMultipleWorksheets(selectedWorksheets);
										}}
										className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
									>
										<ArrowDownTrayIcon className="w-6 h-6" />
										Download
									</button>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={() => {
											setSharePromptOpen(true);
											//share(selectedWorksheets)
										}}
										className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
									>
										<LinkIcon className="w-6 h-6" />
										Share
									</button>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={() => {
											deleteMultipleWorksheets(selectedWorksheets);
										}}
										className={`${active && "bg-gray-100 dark:bg-darkdecoration dark:text-text"} py-1 px-3 rounded-md flex gap-2 w-full`}
									>
										<TrashIcon className="w-6 h-6" />
										Delete
									</button>
								)}
							</Menu.Item>
						</Menu.Items>
					</Menu>
				) : i === 0 ? (
					<span className="flex justify-start">
						<button
							onClick={() => {
								setSelectedWorksheets([]);
								setCheckboxesVisible(false);
							}}
							className="flex py-1"
						>
							<ArrowUturnLeftIcon className="w-8 h-8 hover:text-gray-500 duration-100" />
						</button>
					</span>
				) : (
					<button
						className={"flex items-center justify-center gap-1 " + (sorting === val ? "underline underline-offset-4 decoration-2 decoration-contrast " : "")}
						onClick={() => {
							setSorting(val);
						}}
					>
						<span>{val}</span>
						<ChevronDownIcon className="w-5 h-5" />
					</button>
				)}
			</th>
		));
	};

	const defaultHeader: () => JSX.Element[] = () => {
		const header = ["", "Name", "Course", "Time", "New"];
		return header.map((val, i) => (
			<th
				key={i}
				scope="col"
				className={
					"py-3 dark:bg-darkgray w-full h-full " +
					(i === header.length - 1 ? "rounded-tr-md " : "") +
					(i === 1 ? "md:rounded-tl-md lg:rounded-none " : "") +
					(val.includes("selected") || i === 0 ? "pl-6 justify-start rounded-tl-md md:hidden lg:inline-block" : val.toLowerCase().includes("new") ? "text-right pr-1.5 justify-start relative" : "px-6 text-center")
				}
			>
				{i === 0 ? (
					!checkboxesVisible ? (
						<span className="flex flex-1 items-center justify-center gap-1 ">
							<button
								onClick={() => {
									if (checkboxesVisible) setSelectedWorksheets([]);
									setCheckboxesVisible(!checkboxesVisible);
								}}
								disabled={val.toLowerCase().includes("new") ? true : false}
								className="flex py-1"
							>
								<Square2StackIcon className="w-7 h-7 hover:text-gray-500 duration-100" />
							</button>
						</span>
					) : (
						<span className="flex justify-start">
							<button
								onClick={() => {
									if (checkboxesVisible) setSelectedWorksheets([]);
									setCheckboxesVisible(!checkboxesVisible);
								}}
								disabled={val.toLowerCase().includes("new") ? true : false}
								className="flex py-1"
							>
								<ArrowUturnLeftIcon className="w-7 h-7 hover:text-gray-500 duration-100" />
							</button>
						</span>
					)
				) : val.toLowerCase().includes("new") ? (
					<div className="flex-1">
						<Menu>
							<Menu.Button>
								<span className={"bg-primary rounded-md py-1 px-4 flex items-center justify-center gap-2 mr-2 dark:bg-darkdecoration dark:text-text " + (sorting === val ? "underline underline-offset-4 decoration-2 decoration-contrast " : "")}>
									<span>{val}</span>
									<PlusIcon className="w-7 h-7" />
								</span>
							</Menu.Button>
							<Menu.Items className="absolute top-full flex flex-col items-start bg-secondary dark:bg-darkgray shadow-lg rounded-md px-3 py-2 font-light z-10">
								<Menu.Item>
									{({ active }) => (
										<button
											onClick={() => {
												setIsRenderingNewFolder(true);
												setFolderNamingOpen(true);
											}}
											className={`${active && "bg-primary dark:bg-darktan dark:text-text"} py-3 px-3 rounded-md flex gap-2 w-full`}
										>
											<FolderPlusIcon className="w-6 h-6" />
											Folder
										</button>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
											onClick={() => {
												setGeneratorOpen(true);
											}}
											className={`${active && "bg-primary dark:bg-darktan dark:text-text"} py-3 px-3 rounded-md flex gap-2 w-full`}
										>
											<DocumentPlusIcon className="w-6 h-6" />
											Worksheet
										</button>
									)}
								</Menu.Item>
							</Menu.Items>
						</Menu>
					</div>
				) : (
					<button
						className={"flex items-center justify-center gap-1 " + (sorting === val ? "underline underline-offset-4 decoration-2 decoration-contrast " : "")}
						onClick={() => {
							setSorting(val);
						}}
						disabled={val.toLowerCase().includes("new folder") ? true : false}
					>
						<span>{val}</span>
						<ChevronDownIcon className="w-5 h-5" />
					</button>
				)}
			</th>
		));
	};

	const noData = () => (
		<div className="w-1/2 h-full flex justify-center items-center gap-3 text-gray-500">
			<MagnifyingGlassIcon className="w-12 h-12 dark:text-white" />
			<div className="w-fit flex flex-col items-start text-sm lg:text-base font-normal dark:text-white">
				<span>Looks like you don't have any worksheets! </span>
				<button
					onClick={() => {
						setGeneratorOpen(true);
					}}
					className="text-contrast underline"
				>
					Make one now
				</button>
			</div>
		</div>
	);

	const notFull = () => <div className="text-sm lg:text-base font-normal w-1/2 h-full flex justify-center items-center text-gray-500 dark:text-darktext">No more files.</div>;

	const goToFilepath = (pos: number, pathArr: string[]) => {
		if (pos === 0) {
			navigate("/worksheets/");
		} else {
			const croppedPath = pathArr.slice(0, pos);
			navigate("/worksheets/?p=" + croppedPath.join("/"));
		}
	};

	const openFolder = (folderName: string) => {
		const curDir = searchParams.get("p");
		if (curDir === null) {
			navigate("/worksheets/?p=" + folderName);
		} else {
			navigate("/worksheets/?p=" + curDir + "/" + folderName);
		}
	};

	return (
		<div className="w-full h-full relative flex flex-col items-center text-xs sm:text-sm md:text-base lg:text-lg text-gray-700 dark:bg-darkbg dark:text-white">
			{sharePrompt()}
			<div className="w-5/6 max-w-6xl mt-6 p-5">
				<span className="font-semibold text-left dark:text-darktext">My Worksheets</span>
				<div className="w-full flex items-center justify-between">
					<p className="w-1/2 mt-1 text-sm lg:text-base font-normal text-gray-500 dark:text-white">Browse a list of your previously generated worksheets. Download, manage and view all your content here!</p>
					<input
						type="text"
						value={searchInput}
						onChange={(e) => {
							setSearchInput(e.target.value);
						}}
						className="w-2/5 max-h-12 rounded-md px-6 py-3 bg-flatlight placeholder:text-gray-500 border-none focus:ring-0 focus:bg-flat dark:bg-darkgray dark:placeholder:text-gray-400"
						maxLength={20}
						placeholder="Search"
					/>
				</div>
			</div>

			<span className="pt-2 -mb-2 text-[12pt] flex text-left w-5/6 max-w-6xl font-light">
				<FolderIcon className="w-5 mr-2 ml-1" />
				<button className="underline decoration-2 underline-offset-4 decoration-primary" onClick={() => goToFilepath(0, [""])}>
					{userName}
				</button>{" "}
				{pathArr?.map((str, i) => {
					return (
						<span key={i}>
							&nbsp;/&nbsp;
							<button className="underline decoration-2 underline-offset-4 decoration-primary" onClick={() => goToFilepath(i + 1, pathArr)}>
								{str}
							</button>
						</span>
					);
				})}
			</span>

			{renderNewFolder()}

			<Table
				sorting={{
					sorting: sorting,
					setSorting: (val) => {
						setSorting(val);
					},
				}}
				totalResults={totalResults}
				tableData={getTableData()}
				setDisplayNum={(val) => {
					setDisplayNum(val);
				}}
				notFull={notFull()}
				noData={noData()}
				loading={loading}
				cols={selectedWorksheets.length === 0 ? ["", "Name", "Course", "Time", ""] : []}
				colsData={selectedWorksheets.length === 0 ? defaultHeader() : selectedHeader()}
				customHeader={true}
			/>

			<Dialog open={generatorOpen} onClose={() => setGeneratorOpen(false)} className="z-10 relative" ref={generatorRef}>
				<div className="fixed inset-0 bg-opacity-20 dark:bg-opacity-30 bg-black" aria-hidden="true" />
				<div className="fixed inset-0 flex items-center justify-center">
					<Dialog.Panel className="w-3/4 max-w-4xl h-5/6 rounded-md bg-white dark:bg-darkbg z-10 font-poppins px-16 pt-10 flex flex-col justify-around">
						<Dialog.Title className="text-3xl font-semibold text-gray-700 dark:text-darkdecoration">Create Worksheet</Dialog.Title>
						<Dialog.Description as="div" className="flex items-center justify-center w-full h-full">
							<WorksheetGenerator
								updateDarkMode={updateDarkMode}
								closed={() => {
									fetchWorksheetData();
									setGeneratorOpen(false);
								}}
								reload={() => {
									fetchWorksheetData();
								}}
								pathArr={pathArr}
							/>
						</Dialog.Description>
					</Dialog.Panel>
				</div>
			</Dialog>
		</div>
	);
};

export default AllWorksheets;
