import { useNavigate } from "react-router";

const NotFound = () => {
	let navigate = useNavigate();
	const routeChange = (path: string) => {
		navigate(path)
	}

	return (
	<div className="w-full h-full">
		<div className="md:pl-24 pt-40">
			<h1 className="pl-12 text-4xl font-semibold text-text">Oops!</h1>
			<h1 className="pl-12 text-4xl font-semibold text-text pt-2 pb-8 pr-12 whitespace-wrap">Page not found 🥴</h1>
			<div className="pl-12"><hr className="border-4 border-contrast w-20"></hr></div>
			<p className="pl-12 pt-12 w-2/3 font-medium">Something went wrong.</p>
			<p className="pl-12 w-4/5 md:w-3/5 font-medium">It looks like the current link doesn't work or something doesn't work. We couldn't find the request page.</p>
			<div className="pl-12 md:w-fit flex-wrap space-x-4 pt-8">
				<button onClick={() => {routeChange(``)}} className="duration-150 w-28 md:w-40 px-6 py-3 rounded-lg bg-flat hover:bg-primary shadow-md font-semibold text-xl disabled:opacity-40">Dashboard</button>
				<button onClick={() => {navigate(-1)}} className="duration-150 w-28 md:w-40 px-6 py-3 rounded-lg bg-neutral hover:bg-contrast shadow-md font-semibold text-xl">Go back</button>
			</div>
		</div>
	</div>
	);
};

export default NotFound;